import { Component, Fragment, useState } from "react";

import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import GoogleMap from "../component/sidebar/googlemap";
import axios from "axios";
import Swal from "sweetalert2";



const ContactPage = () => {
    const conSubTitle = "आमच्याशी संपर्क करण्यासाठी खालील फॉर्म भर.";
const conTitle = "आमच्याशी संपर्क करण्यासाठी खालील फॉर्म भरा.";
const btnText = "सबमिट करा";


const contactList = [
    {
        imgUrl: 'assets/images/icon/01.png',
        imgAlt: 'contact icon',
        title: 'Office Address',
        desc: 'प्लॉट क्र. T/18, श्री ज्ञानदीप कॅम्पस, आयटी पार्क एस.टी.पी.आय, गरव्हारे स्टेडियम समोर, चिकलठाणा-एमआयडीसी  , छत्रपती संभाजीनगर, महाराष्ट्र ४३१००६.',
    },
    {
        imgUrl: 'assets/images/icon/02.png',
        imgAlt: 'contact icon',
        title: 'Mobile number',
        desc: ' +91 9049446044',
        desc2:'+91 9049445044'
    },
    {
        imgUrl: 'assets/images/icon/03.png',
        imgAlt: 'contact icon',
        title: 'Email',
        desc: 'info@shivcharitra.com',
    },
    // {
    //     imgUrl: 'assets/images/icon/04.png',
    //     imgAlt: 'contact icon',
    //     title: 'Our website',
    //     desc: 'http://manavlokvikas.com/',
    // },
]
const [name, setName] = useState('');
const [email, setEmail] = useState('');
const [number, setNumber] = useState('');
const [subject, setSubject] = useState('');
const [message, setMessage] = useState('');

const handleSubmit = (e) => {
  e.preventDefault();

  // Create an object with the form data
  const formData = { 
    "name":name,  
    "number":number, 
    "email":number, 
    "subject":subject, 
    "message":message 
    }

  // Send form data to the API
  axios.post('http://admin.manavlokvikas.com/sendEmail', formData)
    .then(response => {
      // Handle API response
      console.log(response.data);
      if(response.data.success===true){
        Swal.fire({
          icon: 'success',
          title:"आमच्याशी संपर्क साधल्याबद्दल धन्यवाद",
         
        })
      }else{
        Swal.fire({
          icon: 'success',
          title:"Something went wrong",
         
        })
      }
    })
    .catch(error => {
      // Handle error
      console.error(error);
    });
};
    return ( 
        <Fragment>
            <Header />
            <PageHeader title={'संपर्क'} curPage={'Contact Us'} />
            <div className="map-address-section padding-tb section-bg">
                <div className="container">
                    {/* <div className="section-header text-center">
                        <span className="subtitle">{subTitle}</span>
                        <h2 className="title">{title}</h2>
                    </div> */}
                    <div className="section-wrapper">
                        <div className="row flex-row-reverse">
                            <div className="col-xl-4 col-lg-5 col-12">
                                <div className="contact-wrapper">
                                    {contactList.map((val, i) => (
                                        <div className="contact-item" key={i}>
                                            <div className="contact-thumb">
                                                <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                            </div>
                                            <div className="contact-content">
                                                <h6 className="title">{val.title}</h6>
                                                <p>{val.desc2}</p>
                                                <p>{val.desc}</p>
                                                
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="col-xl-8 col-lg-7 col-12">
                                <GoogleMap />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="contact-section padding-tb">
                <div className="container">
                    <div className="section-header text-center">
                        <span className="subtitle">{conSubTitle}</span>
                        <h2 className="title">{conTitle}</h2>
                    </div>
                    <div className="section-wrapper">
                    <form className="contact-form" onSubmit={handleSubmit}>
      <div className="form-group">
        <input
          type="text"
          name="name"
          placeholder="संपूर्ण नाव*"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
      </div>
      <div className="form-group">
        <input
          type="text"
          name="email"
          placeholder=" इमेल आयडी"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </div>
      <div className="form-group">
        <input
          type="text"
          name="number"
          placeholder="मोबाईल नंबर*"
          value={number}
          onChange={(e) => setNumber(e.target.value)}
          required
        />
      </div>
      <div className="form-group">
        <input
          type="text"
          name="subject"
          placeholder="विषय *"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          required
        />
      </div>
      <div className="form-group w-100">
        <textarea
          rows="8"
          type="text"
          name="message"
          placeholder="संदेश"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        ></textarea>
      </div>
      <div className="form-group w-100 text-center">
        <button className="lab-btn" type="submit"><span>{btnText}</span></button>
      </div>
    </form>
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    );
}



export default ContactPage;