import { useEffect, useState } from "react";
import axios from "axios";
import LoadingOverlay from 'react-loading-overlay';
import ApiConfig from "../ApiConfig";
import { Carousel } from "react-bootstrap";



const eventOneImagess = [
  {
    imgUrl: "assets/images/slideshow/event-1/1.jpeg",
  },
  {
    imgUrl: "assets/images/slideshow/event-1/2.jpeg",
  },
  {
    imgUrl: "assets/images/slideshow/event-1/3.jpeg",
  },
  {
    imgUrl: "assets/images/slideshow/event-1/4.jpeg",
  },
  {
    imgUrl: "assets/images/slideshow/event-1/5.jpeg",
  },
  {
    imgUrl: "assets/images/slideshow/event-1/6.jpeg",
  },
  {
    imgUrl: "assets/images/slideshow/event-1/7.jpeg",
  },
  {
    imgUrl: "assets/images/slideshow/event-1/8.jpeg",
  },
  {
    imgUrl: "assets/images/slideshow/event-1/9.jpeg",
  },
  {
    imgUrl: "assets/images/slideshow/event-1/10.jpeg",
  },
  {
    imgUrl: "assets/images/slideshow/event-1/11.jpeg",
  },
  {
    imgUrl: "assets/images/slideshow/event-1/12.jpeg",
  },
  {
    imgUrl: "assets/images/slideshow/event-1/13.jpeg",
  },
  {
    imgUrl: "assets/images/slideshow/event-1/14.jpeg",
  },
  {
    imgUrl: "assets/images/slideshow/event-1/15.jpeg",
  },
  {
    imgUrl: "assets/images/slideshow/event-1/16.jpeg",
  },
]

const courseList = [
  {
    imgUrl: "assets/images/gallery/1682082739337.jpg",
  },
  {
    imgUrl: "assets/images/gallery/1682082739370.jpg",
  },
  {
    imgUrl: "assets/images/gallery/1682082739542.jpg",
  },
  {
    imgUrl: "assets/images/gallery/1682082739567.jpg",
  },
  {
    imgUrl: "assets/images/gallery/FB_IMG_1552276907863.jpg",
  },
  {
    imgUrl: "assets/images/gallery/IMG_20141213_184330.jpg",
  },
  {
    imgUrl: "assets/images/gallery/IMG_20141213_194347.jpg",
  },
  {
    imgUrl: "assets/images/gallery/IMG_20141213_195641.jpg",
  },
  {
    imgUrl: "assets/images/gallery/IMG_20190511_111915.jpg",
  },
  {
    imgUrl: "assets/images/gallery/IMG_20190511_111942.jpg",
  },
  {
    imgUrl: "assets/images/gallery/IMG_20190614_123618.jpg",
  },
  {
    imgUrl: "assets/images/gallery/IMG_20190614_124244.jpg",
  },
  {
    imgUrl: "assets/images/gallery/IMG_20190614_131657.jpg",
  },
  {
    imgUrl: "assets/images/gallery/IMG_20190614_133059.jpg",
  },
  {
    imgUrl: "assets/images/gallery/IMG_20190614_134219.jpg",
  },
  {
    imgUrl: "assets/images/gallery/IMG_20190817_162551.jpg",
  },
  {
    imgUrl: "assets/images/gallery/IMG_20200308_174412.jpg",
  },
  {
    imgUrl: "assets/images/gallery/IMG_20200308_174440.jpg",
  },
  {
    imgUrl: "assets/images/gallery/IMG_20200308_175610.jpg",
  },
];



const Course = () => {
  const baseURL = ApiConfig();
  const [loading, setLoading] = useState(false);
  const [folderList, setFolderList]=useState([]);
  const [eventOneImages, seteventOneImages]=useState([])
useEffect(() => {
  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await fetch(baseURL + 'folderList', {
        method: 'GET',
      });

      if (response.ok) {
        const data = await response.json();
        setFolderList(data);
        setLoading(false);
        console.log(data);
      } else {
        setLoading(false);
        console.log('Error:', response.status);
      }
    } catch (error) {
      setLoading(false);
      console.log('Error:', error.message);
    }
  };

  const fetchPhoto = async () => {
    try {
      setLoading(true);
      const response = await fetch(baseURL + 'photosList', {
        method: 'GET',
      });

      if (response.ok) {
        const data = await response.json();
        seteventOneImages(data);
        setLoading(false);
        console.log(data);
      } else {
        setLoading(false);
        console.log('Error:', response.status);
      }
    } catch (error) {
      setLoading(false);
      console.log('Error:', error.message);
    }
  };

  fetchData();
  fetchPhoto();
}, []);
  return (

    <div className="course-section padding-tb section-bg">
       <LoadingOverlay active={loading} spinner text="Loading.....">
      <div className="container">
        {/* <div className="section-header text-center">
          <span className="subtitle">{subTitle}</span>
          <h2 className="title">{title}</h2>
        </div> */}
        <div className="section-wrapper">
          <div className="row g-4 justify-content-center">
            {folderList.map((index, i) => {return(
            <div className=" col-12 col-xl-5 col-lg-6 col-md-8 col-sm-10" key={i}>
              <Carousel controls={false} indicators={false} interval={3000}>
                {eventOneImages.map((item, i) => {
                  if(index.id===item.folder_id){
                    return (
                      <Carousel.Item>
                        <img
                          className="carousel-images"
                          src={`${baseURL}${item.photo}`} 
                          alt="gallery-image"
                        />
                      </Carousel.Item>
                    );
                  }
                })}
              </Carousel>
              <h3 className=" event-titles">{index.title}</h3>
            </div>
 )})}
 

          </div>
        </div>
      </div>
      </LoadingOverlay>
    </div>
  );
};

export default Course;
