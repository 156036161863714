import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Logo from "../../assets/images/logo/shivlogo.png";

const phoneNumber = "+91 9049 44 50 44 ";
const address =
  "info@shivcharitra.com";


  const socialList = [
    {
        iconName: 'icofont-facebook',
        siteLink: 'https://www.facebook.com/profile.php?id=61556235654270&mibextid=ZbWKwL',
        className: 'facebook',
    },
    // {
    //     iconName: 'icofont-twitter',
    //     siteLink: '#',
    //     className: 'twitter',
    // // },
    // {
    //     iconName: 'icofont-linkedin',
    //     siteLink: '#',
    //     className: 'linkedin',
    // },
    {
        iconName: 'icofont-instagram',
        siteLink: '#',
        className: 'instagram',
    },
    {
        iconName: 'icofont-youtube',
        siteLink: '#',
        className: 'youtube',
    },
    {
        iconName: 'icofont-whatsapp',
        siteLink: 'https://wa.me/9049445044?text=How may I help You',
        className: 'whatsapp',
    },
    // {
    //     iconName: 'icofont-pinterest',
    //     siteLink: '#',
    //     className: 'pinterest',
    // },
]
const Header = () => {
  const [menuToggle, setMenuToggle] = useState(false);
  const [socialToggle, setSocialToggle] = useState(false);
  const [headerFiexd, setHeaderFiexd] = useState(false);

  window.addEventListener("scroll", () => {
    if (window.scrollY > 200) {
      setHeaderFiexd(true);
    } else {
      setHeaderFiexd(false);
    }
  });

  return (
    <header
      className={`header-section ${headerFiexd ? "header-fixed fadeInUp" : ""}`}
    >
      <div className={`header-top ${socialToggle ? "open" : ""}`}>
        <div className="container">
          <div className="header-top-area">
            <ul className="lab-ul left">
              <li>
                <i className="icofont-ui-call"></i> <span>{phoneNumber}</span>
              </li>
              <li>
                <i className="icofont-email"></i> {address}
              </li>
            </ul>
            <ul className="lab-ul social-icons d-flex align-items-center">
                            <li><p>Find us on : </p></li>
                            {socialList.map((val, i) => (
                                <li key={i}><a href={val.siteLink} target="_blank"><i className={val.iconName}></i></a></li>
                            ))}
                        </ul>
          </div>
        </div>
      </div>
      <div className="header-bottom">
        <div className="container-xl">
          <div className="header-wrapper d-flex jusify-content-evenly">
            <div className="logo">
              <Link to="/">
                <img src={Logo} className="img-fluid" alt="logo" />
              </Link>
            </div>
            <div className="menu-area">
              <div className="menu">
                <ul className={`lab-ul ${menuToggle ? "active" : ""}`}>
                  <li>
                    <NavLink to="/">होमपृष्ठ</NavLink>
                  </li>
    
                  <li>
                    <NavLink to="/course-single">शिवचरित्र परीक्षा</NavLink>
                  </li>
                  {/* <li  className="menu-item-has-children">
                  <a href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-offset="0,0">अभियान &nbsp;</a>
                   
                    <ul  className="lab-ul dropdown-menu">
                      <li>
                        <NavLink to="/blog">लेख</NavLink>
                      </li>
                      <li><NavLink to="/abhiyan-gallery">फोटो</NavLink></li>
                    
                      <li><NavLink to="/pramukh-margdarshak">मार्गदर्शक</NavLink></li>
                      <li><NavLink to="/mukhai">मुख्य कायदेविषयक सल्लागार समिती</NavLink></li>
                      <li><NavLink to="/rajya-simti">राज्य समन्वय समिती</NavLink></li>
                    </ul>
                  </li> */}
                       {/* <li>
                    <NavLink to="/instructor">संयोजक</NavLink>
                  </li> */}
                  {/* <li>
                    <NavLink to="/instructor">परीक्षा नोंदणी</NavLink>
                  </li> */}
                  {/* <li className="menu-item-has-children">
                  <a href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-offset="0,0">संयोजक  &nbsp;</a>
                    
                    <ul  className="lab-ul dropdown-menu">
                      <li>
                        <NavLink to="/instructor">राज्य संयोजक</NavLink>
                      </li>
                      <li><NavLink to="/jilha-sanyojak">जिल्हा संयोजक</NavLink></li>
                      <li><NavLink to="/taluka-sanyojak">तालुका संयोजक</NavLink></li>
                      <li><NavLink to="/gat-sanyojak">गट- विभाग संयोजक</NavLink></li>
                      <li><NavLink to="/sansta-sanyojak">संस्था / संघटना / मंडळ समन्वयक</NavLink></li>
                      <li><NavLink to="/shala-sanyojak">शाळा / क्लासेस / अकॅडमी / महाविघालय / विद्यापिठ</NavLink></li>
                    </ul>
                  </li> */}
                     <li>
                        <NavLink to="/gallery">फोटो गॅलरी</NavLink>
                      </li>
                  {/* <li className="menu-item-has-children">
                  <a href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-offset="0,0">गॅलरी &nbsp;</a>
   
                    <ul  className="lab-ul dropdown-menu">
                      <li>
                        <NavLink to="/gallery">फोटो गॅलरी</NavLink>
                      </li>
                      <li><NavLink to="/video-gallery">व्हिडीओ गॅलरी</NavLink></li>
                     
                    </ul>
                  </li> */}
                  {/* <li>
                    <NavLink to="/about">आमच्या बद्दल</NavLink>
                  </li> */}
                 <li>
                    <NavLink to="/about">आमच्या बद्दल </NavLink>
                  </li>
                  <li>
                    <NavLink to="/contact">संपर्क</NavLink>
                  </li>

                  {/* <li>
                    <NavLink to="/donate">देणगी </NavLink>
                  </li> */}
                </ul>
              </div>

              <Link to="/login" className="login">
                <i className="icofont-user"></i> <span>लॉगइन</span>{" "}
              </Link>
              <Link to="/signup" className="signup">
                <i className="icofont-users"></i> <span>नोंदणी</span>{" "}
              </Link>

              <div
                className={`header-bar d-lg-none ${menuToggle ? "active" : ""}`}
                onClick={() => setMenuToggle(!menuToggle)}
              >
                <span></span>
                <span></span>
                <span></span>
              </div>
              <div
                className="ellepsis-bar d-lg-none"
                onClick={() => setSocialToggle(!socialToggle)}
              >
                <i className="icofont-info-square"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
