


const subTitle = "शिव चरित्र ";
const title = "छत्रपती शिवाजी महाराजांसारखा जनता राजा आपल्या महाराष्ट्राला लाभला, त्यांच्या जीवनाविषयी आणि त्यांच्या मूल्यांविषयी आपण सर्वांना माहिती असणे साहजिकच आहे. तीच माहिती आणखी वाढवण्याच्या उद्देशाने आणि त्यामध्ये आणखी वृध्दी करण्यासाठी आपली ";
const subHeading = "परीक्षेचे नियम";
const desc1 = "परीक्षा मराठी व इंग्रजी दोन्ही पैकी एका भाषेत देता येईल.";
const desc2 = " अभ्यास करण्यासाठी आपण शिवाजी महाराज्यांच्या जीवनावरील पुस्तके आणि इतर लेख वाचू शकतात, आमच्या कडूनही अशी अभ्यासासाठी  काही माहिती वेगवेगळ्या स्वरुपात आम्ही उपलब्ध करून देणार आहोत";
const desc3 = "ऑनलाईन परिक्षा दिनांक 20/04/2024 ते 26/04/2024 या काळात आयोजित करण्यात येणार आहे. या काळात वेगवेगळ्या टप्प्यात परीक्षा घेतली जातील त्याबद्दल चे वेळापत्रक https://shivcharitra.com या संकेतस्थळावर प्रदर्शित करण्यात येईल. ";
const desc4 = "परिक्षार्थींनी भरलेली माहिती चुकीची आढळल्यास सदर परिक्षार्थीस बक्षीसास अपात्र केले जाईल.";
const desc5 = "";



const aboutList = [
    {
        imgUrl: 'assets/images/about/icon/01.jpg',
        imgAlt: 'about icon rajibraj91 rajibraj',
        title: 'Skilled Instructors',
        desc: 'Distinctively provide acces mutfuncto users whereas communicate leveraged services',
    },
    {
        imgUrl: 'assets/images/about/icon/02.jpg',
        imgAlt: 'about icon rajibraj91 rajibraj',
        title: 'Get Certificate',
        desc: 'Distinctively provide acces mutfuncto users whereas communicate leveraged services',
    },
    {
        imgUrl: 'assets/images/about/icon/03.jpg',
        imgAlt: 'about icon rajibraj91 rajibraj',
        title: 'Online Classes',
        desc: 'Distinctively provide acces mutfuncto users whereas communicate leveraged services',
    },
]


const About = () => {
    return (
        <div className="about-section">
            <div className="container">
                <div className="row pt-5 justify-content-evenly row-cols-xl-2 row-cols-1 align-items-end flex-row-reverse">
                    <div className="col-lg-6 pt-5">
                        <div className="about-right">
                            <div className="section-header">
                                <span className="subtitle" style={{letterSpacing: "0px"}}>{subTitle}</span>
                                <h6 className="title">{title}</h6>
                                <br></br>

                                <div className="main-part">
                                <div className="course-item">
                                    <div className="course-inner">
                                        <div className="course-content">
                                            {/* <h3>संविधान आहे महान, सर्वांना हक्‍क समान दर्जाची, संधीची समानता, हीच संविधानाची महानता</h3> */}
                                            
                                            <h4 style={{paddingTop: "5px"}}>परीक्षेचे नियम</h4>
                                            <ul className="lab-ul " style={{fontSize:"20px"}}>
                                                <li><i className="icofont-tick-mark"></i>परीक्षा मराठी व इंग्रजी दोन्ही पैकी एका भाषेत देता येईल.</li>
                                                <li><i className="icofont-tick-mark"></i>अभ्यास करण्यासाठी आपण शिवाजी महाराज्यांच्या जीवनावरील पुस्तके आणि इतर लेख वाचू शकतात, आमच्या कडूनही अशी अभ्यासासाठी  काही माहिती वेगवेगळ्या स्वरुपात आम्ही उपलब्ध करून देणार आहोत</li>
                                                <li><i className="icofont-tick-mark"></i>ऑनलाईन परिक्षा दिनांक 20/04/2024 ते 26/04/2024 या काळात आयोजित करण्यात येणार आहे. या काळात वेगवेगळ्या टप्प्यात परीक्षा घेतली जातील त्याबद्दल चे वेळापत्रक https://shivcharitra.com या संकेतस्थळावर प्रदर्शित करण्यात येईल. </li>

                                              
                                                <li><i className="icofont-tick-mark"></i>परिक्षार्थींनी भरलेली माहिती चुकीची आढळल्यास सदर परिक्षार्थीस बक्षीसास अपात्र केले जाईल.</li>

                                                
                                               
    
                  


                                            </ul>
                                        </div>
                                    </div>
                                </div>

 
                                {/* <Author /> */}
                                {/* <Comment /> */}
                                {/* <Respond /> */}
                            </div>
                               
                            </div>
                            {/* <div className="section-wrapper">
                                <ul className="lab-ul">
                                    {aboutList.map((val, i) => (
                                        <li key={i}>
                                            <div className="sr-left">
                                                <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                            </div>
                                            <div className="sr-right">
                                                <h5>{val.title}</h5>
                                                <p>{val.desc}</p>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div> */}
                        </div>
                    </div>
                    <div className="col-lg-6 d-flex justify-content-center">
                        <div className="about-left">
                            <div className="about-thumb">
                                <img src="assets/images/about/image_2024_01_30T17_31_53_551Z.png" alt="about" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;