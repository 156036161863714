import React from "react";
import { Link } from "react-router-dom";

export const MadadFormOne = ({ group }) => {
    return (
        <div className="account-wrapper mt-5">
            <h3 className="title">{group}</h3>
            <form className="account-form">
                <div className="form-group">
                    <p className="form-input-headings">
                        संपूर्ण नाव  <span className="text-danger">*</span>
                    </p>

                    <input
                        type="text"
                        name="name"
                        onKeyDown={(e) => {
                          const keyCode = e.keyCode || e.which;
                          const keyValue = String.fromCharCode(keyCode);
                          const regex = /^[a-zA-Z\s]+$/; // Regular expression to match alphabetic characters and spaces
            
                          // Allow backspace (keyCode 8), alphabetic characters, and spaces
                          if (keyCode !== 8 && !regex.test(keyValue)) {
                            e.preventDefault();
                          }
                        }}
                        placeholder="(आडनाव)  (स्वतःचे नाव)  ( वडिलांचे नाव )"
                    />
                </div>
                <div className="form-group">
                    <p className="form-input-headings">
                        जन्म दिनांक <span className="text-danger">*</span>
                    </p>
                    <input
                        type="date"
                        name="name"
                        placeholder="जन्म तारीख निवडा / लिहा"
                    />
                </div>
                <div className="form-group">
                    <p className="form-input-headings">
                        आधार नंबर <span className="text-danger">*</span>
                    </p>
                    <input
                        type="number"
                        name="name"
                        placeholder="आधार क्रमांक येथे लिहा"
                    />
                </div>
                <div className="form-group">
                    <p className="form-input-headings">
                        मो नं
                        <span className="text-danger">*</span>
                    </p>
                    <input type="number" name="name" placeholder="+91" />
                </div>
               
                <div className="form-group">
          <p className="form-input-headings">
            बँक नाव
            <span className="text-danger">*</span>
          </p>
          <input
            type="text"
            name="password"
          // placeholder="बँक नाव"
          />
        </div>
        <div className="form-group">
          <p className="form-input-headings">
          A / C No :-
            <span className="text-danger">*</span>
          </p>
          <input
            type="text"
            name="password"
          // placeholder="बँक नाव"
          />
        </div>
        <div className="form-group">
          <p className="form-input-headings">
            IFSC कोड
            <span className="text-danger">*</span>
          </p>
          <input
            type="text"
            name="password"
          // placeholder="IFSC कोड"
          />
        </div>
        <div className="form-group">
          <p className="form-input-headings">
          शाखेचे नाव :
            <span className="text-danger">*</span>
          </p>
          <input
            type="text"
            name="password"
          // placeholder="IFSC कोड"
          />
        </div>
               
                <div className="form-group">
                    <p className="form-input-headings">
                        संपूर्ण पत्ता  <span className="text-danger">*</span>
                    </p>

                    <input type="text" name="name" placeholder="Email" />
                </div>
                <div className="form-group">
                    <p className="form-input-headings">
                    जवळच्या कोणत्याहि ५ मित्र / नातेवाईक यांचे नाव व मो.नं.  <span className="text-danger">*</span>
                    </p>

                    <input type="text" name="name" placeholder="Email" />
                </div>
                <div className="form-group">
                    <p className="form-input-headings">
                    मदतिचे स्वरूप थोडक्यात लिहा  <span className="text-danger">*</span>
                    </p>

                    <textarea type="text" name="name" placeholder="Description"></textarea>
                </div>
               
                <div className="form-group">
                    <button className="lab-btn">
                        <span>Submit</span>
                    </button>
                </div>
            </form>
            
        </div>
    );
};
