import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import Header from "../component/layout/header";
import { useParams , useNavigate} from 'react-router-dom';
import Footer from '../component/layout/footer';
import ApiConfig from '../component/ApiConfig';
import LoadingOverlay from 'react-loading-overlay';
import Swal from 'sweetalert2';
import Logo from '../assets/images/logo/shivlogo.png'
import useRazorpay from "react-razorpay";
import { useLocation } from "react-router";
import axios from 'axios';
const StudentPaymentDetail = () => {
  const location = useLocation();
  const Razorpay = useRazorpay();

  const navigateTo=useNavigate();
  const { state } = location;
  const [userId, setUserId] = useState(state?.userId || null);
  const [amount, setAmount]=useState(state?.amount || null)
  const [sanId, setSanid]=useState(state?.sanId || null)
  const [sanCat, setSancat]=useState(state?.sanCat || null)
  const [sanPer, setSanper]=useState(state?.sanPer || null)
  const [name, setName]=useState(state?.name || null)
  const [mobileno, setMobileno]=useState(state?.mobileno || null)
  const [sanRef, setSanRef]=useState(state?.sanRef || null)
  const [aadhar, setaadhar]=useState(state?.aadhar || null)
  const [sec, setSec]=useState(state?.sec || null)
  const [group, setgroup]=useState(state?.group || null)
  const [email, setemail]=useState(state?.email || null)
  const [address, setaddress]=useState(state?.address || null)
  const [sanName, setsanName]=useState(state?.sanName || null)
  
 

  async function handlePayment() {
    const handlePaymentSuccess = (payment) => {
          // Show a success message in an alert
    alert('Payment Successful!\nPayment ID: ' + payment.razorpay_payment_id);

    // Redirect to the homepage
    window.location.href = '/'; // Replace '/' with the actual URL of your homepage
      console.log(payment);
    };
    const handlePaymentError = (error) => {
      console.log(error);
    };

    const generateOrderId = () => {
      return Math.floor(Math.random() * 1000000);
    };

    const orderId = generateOrderId();

    const options = {
      key: "rzp_test_My850RDemeUA61",
      amount: amount*100,
      currency: "INR",
      name: "शिवचरित्र",
      // order_id: orderId,
      image: {Logo},
      description: "Registration fees",
       callback_url: "https://eneqd3r9zrjok.x.pipedream.net/",
      prefill: {
        name: name,
        email: email,
        contact: mobileno
      },
      handler: async function (response) {
         console.log(response)
      },
      notes: {
        address: "Razorpay Corporate Office"
      },
      theme: {
        color: "#f16126"
      },
      onPaymentSuccess: handlePaymentSuccess,
      onPaymentError: handlePaymentError
    };

    // Include the Razorpay script dynamically
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.async = true;
    script.onload = () => {
      const rzp1 = new window.Razorpay(options);
      rzp1.open();
    };

    document.body.appendChild(script);
  }

 
  return (
    <Fragment>
    <Header />
    <div className="login-section padding-tb section-bg">
      <div className="container-fluid">
    <div className="account-wrapper" style={{marginTop:"5rem"}} >
     
          <h5 className="card-title text-center ">Payment Details For</h5>

          <h5 className="card-title text-center mt-2" >विभाग</h5>
          <h5 className="card-title text-center mt-2"  id="vibhag" >{sec}</h5>
          {/* <h5 className="card-title text-center mt-2" >Group <span id="student-group">{group}</span></h5> */}
          <form  method="post" name="customerData"  action="/payment/studentsccavRequestHandler.php">
            <table className="table tabled-sm table-stripped" >
              
              <tbody>
              
                <tr>
                  <td colSpan="2">Compulsory information</td>
                </tr>
                <tr>
                  <td>Amount:</td>
                  <td>
                    <input type="text" name="amount" value={amount} />
                  </td>
                </tr>
                             
               
               
                <tr>
                  <td>Name:</td>
                  <td>
                    <input type="text" name="billing_name" id='billing_name' value={name}  />
                  </td>
                </tr>
                <tr>
                  <td>Address:</td>
                  <td>
                    <input type="text" name="billing_address" id="billing_address" value={address} />
                  </td>
                </tr>
               
                <tr>
                  <td>Mobile no:</td>
                  <td>
                    <input type="text" name="billing_tel" id="billing_tel" value={mobileno}  />
                  </td>
                </tr>
                <tr>
                  <td>Aadhar Number:</td>
                  <td>
                    <input type="text"  id="aadharno"  value={aadhar} />
                  </td>
                </tr>
                <tr>
                  <td>Section</td>
                  <td>
                    <input type="text"  id="sec"  value={sec}  />
                  </td>
                </tr>
            
                <tr>
                  <td> Email:</td>
                  <td>
                    <input type="text" name="billing_email" id="billing_email" value={email}  />
                  </td>
                </tr>
                <tr>
                  <td>Refferal Type :</td>
                  <td>
                    <input type="text" name="billing_cat" id="billing_cat" value={sanCat}  />
                  </td>
                </tr>
                <tr>
                  <td>Refferal code:</td>
                  <td>
                    <span>{sanRef}</span>
                  </td>
                </tr>
                <tr>
                  <td>Refferal Name:</td>
                  <td>
                    {sanName}
                  </td>
                </tr>
              
                
                
                
                
                    <input type="hidden" name="delivery_tel" value="" />
                    <input type="hidden" name="userId"  value={userId}  />
                    <input type="hidden" name="sanId" value={sanId} />
                    <input type="hidden" name="sanRef" value={sanRef} />
                   
                <tr>
                  <td></td>
                  <td>
                    <button className='btn btn-danger' type="button"  onClick={handlePayment}>Pay Now</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
        </div>
    </div>
    </div>

  <Footer/>
  </Fragment>
  )
}

export default StudentPaymentDetail