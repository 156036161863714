import { Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import About from "../component/section/about";
import Achievement from "../component/section/achievement";
import Banner from "../component/section/banner";
// import Blog from "../component/section/blog";
import Category from "../component/section/category";
// import Course from "../component/section/course";
import Instructor from "../component/section/instructor";
import Books from "../component/section/Books";
import OurCertificates from "../component/section/OurCertificates";
import HomeImg from "../component/section/HomeImg";
import HeroVideo from "../component/section/HeroVideo";

const Home = () => {
    return (
        <Fragment>
            <Header />
            <HeroVideo />
            {/* <Banner /> */}
            {/* <Sponsor /> */}
            <Category />
            {/* <Course limit={true}/> */}
            {/* <Books /> */}
            <About />
            {/* <Instructor /> */}
            {/* <Student />
            <Blog /> */}
            {/* <OurCertificates /> */}
            <Achievement />
            {/* <HomeImg /> */}
            <Footer />
        </Fragment>
    );
}
 
export default Home;