import React from 'react'
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
const RefundCancellation = () => {
  return (
    <div>
        <Header />
        <PageHeader title={`Refund and Cancellation Policy`} curPage={`Refund and Cancellation Policy`} />
        <div className='container mt-3 mb-3'>
        <ol>
    <li>
        <strong>Refund and Cancellation Policy</strong>
        <ol>
            <li>Refunds will only be issued in cases where explicitly mentioned in these Terms or as required by law.</li>
            <li>Refund requests must be submitted in writing to <a href="mailto:support@shivcharitra.com">support@shivcharitra.com</a> within the specified timeframe, if any.</li>
        </ol>
    </li>
    <li>
        <strong>Disclaimer</strong>
        <ol>
            <li>
                The materials on our website are provided on an 'as is' basis. We make no warranties, expressed or implied, and hereby disclaim and negate all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.
            </li>
            <li>
                Further, we do not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its website or otherwise relating to such materials or on any sites linked to this site.
            </li>
        </ol>
    </li>
    <li>
        <strong>Limitations</strong>
        <p>
            In no event shall we or our suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on our website, even if we or an authorized representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties or limitations of liability for consequential or incidental damages, these limitations may not apply to you.
        </p>
    </li>
    <li>
        <strong>Governing Law</strong>
        <p>
            These terms and conditions are governed by and construed in accordance with the laws of [Your Country], and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.
        </p>
    </li>
    <li>
        <strong>Modifications</strong>
        <p>
            We may revise these terms of service for its website at any time without notice. By using this website, you are agreeing to be bound by the then-current version of these terms of service.
        </p>
    </li>
 
</ol>

<p>
    By using our website, you hereby consent to our Terms and Conditions and agree to abide by them. <br></br>
    These Terms and Conditions were last updated on 30-Jan, 2024.
</p>

<p>
    <strong>Contact Details for Support or More Information</strong> <br></br>
    Shiv Charitra <br></br>
    <a href="mailto:help@shivcharitra.com">help@shivcharitra.com</a> / <a href="mailto:support@shivcharitra.com">support@shivcharitra.com</a>
</p>

        </div>
        <Footer />
    </div>
  )
}

export default RefundCancellation