const title = "छत्रपती शिवाजी महाराजांसारखा जनता राजा आपल्या महाराष्ट्राला लाभला, त्यांच्या जीवनाविषयी आणि त्यांच्या मूल्यांविषयी आपण सर्वांना माहिती असणे साहजिकच आहे. तीच माहिती आणखी वाढवण्याच्या उद्देशाने आणि त्यामध्ये आणखी वृध्दी करण्यासाठी आपली ...";
const desc = "The most impressive is collection of share me online college courses";
const author = "Rajib Raj";
const reviewCount = "03 reviews";
const videoLink = "#";


const categoryList = [
    {
        link: '#',
        text: 'Adobe XD',
        className: 'course-cate',
    },
    {
        link: '#',
        text: '30% Off',
        className: 'course-offer',
    },
]


const PageHeaderTwo = () => {
    return (
        <div className="pageheader-section style-2">
            <div className="container">
                <div className="row justify-content-center justify-content-lg-between align-items-center flex-row-reverse">
                    <div className="col-lg-7 col-12">
                        <div className="pageheader-thumb">
                            <img src="https://i.pinimg.com/originals/a4/2e/7e/a42e7e4d33fdfe1fe32c2166f0ef8e07.jpg"  alt="rajibraj91" className="w-100 " />
                            <a href={videoLink} className="video-button popup" target="_blank"><i className="icofont-ui-play"></i></a>
                        </div>
                    </div>
                    <div className="col-lg-5 col-12">
                        <div className="pageheader-content">
                            {/* <div className="course-category">
                                {categoryList.map((val, i) => (
                                    <a href={val.link} className={val.className} key={i}>{val.text}</a>
                                ))}
                            </div> */}
                            <h2 className="phs-title">{title}</h2>
                            {/* <p className="phs-desc">{desc}</p> */}
                            {/* <div className="phs-thumb">
                                <img src="assets/images/instructor/single/categoryimage.png" alt="rajibraj91" />
                                <span>{author}</span>
                                <div className="course-reiew">
                                    <Rating />
                                    <span className="ratting-count">{reviewCount}</span>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default PageHeaderTwo;