import { Component, Fragment, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import Achievement from "../component/section/achievement";
import Student from "../component/section/student";
import Category2 from "../component/section/category2"
import Category3 from "../component/section/category3"

const subTitle = "जयप्रकाश टाकणखार";
const title = "हमारा संकल्प सुशिक्षीत, सुरक्षित, समृद्ध भारत";
const title2 = "Make A Global Impact";
const btnText = "Browse All Categories";

const description = "संस्था, संघटना मंडळ हे संविधान जनजागृती अभियान याचा प्रसार व प्रचार करण्यासाठी तसेच परीक्षार्थीची नोंदणी करून घेण्यासाठी आणि इतर संस्था संघटना , मंडळ यांनाअभियानाशी सलग्न करण्याचा अधिकार संस्था, संघटना मंडळ समन्वयकास असेल. संस्था, संघटना मंडळ हे अभियानाच्या प्रक्रियेत सूचना, बदल, व नियोजन प्रक्रियेत मार्गदर्शक म्हणून देखील सहभागी होऊ शकतील."

const sanyojakNiyam=[
    "- महाविद्यालये/विद्यापीठ २५० परिक्षा्थी नोंदणी करणार्‍या प्रत्येकी ६,०००/-",
    "- महाविद्यालवे/विद्यापीठ ५०० पीरक्षा्थी नोंदणी करणाऱ्या प्रत्येकी ११,०००/-",
    "- महाविद्यालयरे/विद्यापीठ १००० WRATH नोंदणी करणार्‍या प्रत्येकी २१,०००/-",
    "- महाविद्यालवे/विद्यापीठ १५०० परिक्षार्थी नोंदणी करणाऱ्या प्रत्येकी ५१,०००/-",
    "- महाविद्यालये/विद्यापीठ २००० UREA नोंदणी करणार्‍या प्रत्येकी १, ५१,०००/-",
    "- महावि द्यालये/विद्यापीठ ५,००० पेक्षा जातत UREN नोंदणी करणाऱ्या २,५१,०००/-",
    "- निर्धारित केलेल्या परीक्षार्थी संख्या पूर्ण न केल्यास सदरील संयोजक बक्षीस पात्र नसेल.",
    "- संयोजकांनी आपला परीक्षार्थी नोंदणी डाटा ऑनलाईन वेबसाईटवर देखील उपलब्ध असेल परंतु लेखी स्वरूपात डायरीमध्ये संकलित करून ठेवणे आवश्यक असेल.",
    "- कोणत्याही संयोजकाकडून गैरवर्तन अथवा कामांमध्ये दोष आढळून आल्यास सदरील संयोजकास कमी करण्याचा कायमस्वरूपी काढून टाकण्याचा निर्णय आयोजकाअधीन असेल.",
    "- कोणत्याही संयोजकाची नियुक्ती ही कायमस्वरूपी नसून अभियानाच्या काळापूर्ती मर्यादित असेल."
]


let categoryItemList = [
    {
        imgUrl: 'assets/images/instructor/collage.jpeg',
        imgAlt: 'category',
        title: 'शत्रुघ्न कसबे,  अश्विन टाकणखार,  अशोक मगर, दिनेश निसर्गंध',
        desc: 'क्रांतीसूर्य बहुउदेशीय सेवाभावी संस्था माजलगाव ',
    },
    {
        imgUrl: 'assets/images/instructor/pratibha-pati.jpg',
        imgAlt: 'category',
        title: 'सौ प्रतिभा वामन पाटीलसंचालिका',
        desc: 'दृष्टी फाउंडेशन , स्पर्धा परीक्षा मार्गदर्शन केंद्र चाळीसगाव',
    },
    {
        imgUrl: 'assets/images/instructor/single/categoryimage.png',
        imgAlt: 'category',
        title: 'सौ सीमा रमेश ठाकूर',
        desc: 'अध्यक्ष विद्रोही संत तुकाराम महाराज शिक्षण संस्था',
    },
    {
        imgUrl: 'assets/images/instructor/single/categoryimage.png',
        imgAlt: 'category',
        title: 'हरी रतनबुवा गिरी',
        desc: 'अध्यक्ष मानव विकास संस्था शेलापुरी',
    },
]



const ShalaSanyojak = () => {

    const [viewMore, setViewMore] = useState(false)

    const handleClickScroll = () => {
        const element = document.getElementById('form-wrapper');
        if (element) {
          // 👇 Will scroll smoothly to the top of the next section
          element.scrollIntoView({ behavior: 'smooth' });
        }
      };

    return (
        <Fragment>
            <Header />
            <PageHeader title={'शाळा / क्लासेस / अकॅडमी / महाविघालय / विद्यापिठ'} curPage={'शाळा / क्लासेस / अकॅडमी / महाविघालय / विद्यापिठ'} />
            <Category2 img={"assets/images/category/shala.jpg"} description={description}  scroll={handleClickScroll}/>
            <div className="category-section padding-tb section-bg style-3">
                <div className="container pb-5">
                    <div className="section-header text-center">
                        <span className="subtitle" style={{letterSpacing: "0px"}}>{subTitle}</span>
                        <h2 className="title">{title}</h2>
                        {/* <h2 className="title">{title2}</h2> */}
                    </div>
                    <div className="section-wrapper">
                        <div className="row g-4 justify-content-center row-cols-lg-4 row-cols-md-3 row-cols-sm-2 row-cols-1">
                            {categoryItemList.map((val, i) => (
                                viewMore ? (
                                    <div className="col" key={i}>
                                        <div className="category-item text-center h-100">
                                            <div className="category-inner h-100">
                                                <div className="category-thumb">
                                                    <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} className="category-image" />
                                                </div>
                                                <div className="category-content">
                                                    <Link to="/signup"><h4>{val.title}</h4></Link>
                                                    <p>{val.desc}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    i < 4 &&
                                    <div className="col" key={i}>
                                        <div className="category-item text-center h-100">
                                            <div className="category-inner h-100">
                                                <div className="category-thumb">
                                                    <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} className="category-image" />
                                                </div>
                                                <div className="category-content">
                                                    <Link to="/signup"><h4>{val.title}</h4></Link>
                                                    <p>{val.desc}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )

                            ))}
                        </div>
                        <div className="row">

                        </div>
                        {
                            categoryItemList.length > 4 &&
                        <div className="text-center mt-5">
                            <button onClick={() => { setViewMore(!viewMore) }} className="lab-btn"><span>{viewMore ? "See less" : "View more"}</span></button>
                        </div>
                        }
                    </div >
                </div >
                <Category3 category="shala-sanyojak"  sanyojakNiyam={sanyojakNiyam} amount={500}/>
            </div >
            {/* <Student /> */}
            < Achievement />
            <Footer />
        </Fragment >
    );
}

export default ShalaSanyojak;