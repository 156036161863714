import { Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import Course from "../component/section/course";
import PageHeader from "../component/layout/pageheader";


const Gallery = () => {
    return (
        <Fragment>
            <Header />
            <PageHeader title={'फोटो गॅलरी'} curPage={'gallery'} />
            <Course />
            <Footer />
        </Fragment>
    );
}
 
export default Gallery;