

const Category2 = ({ scroll, description, img }) => {
    return (
        <div className="category-section padding-tb">
            <div className="container">
                <div className="section-header text-center">
                    {/* <span className="subtitle">{subTitle}</span> */}
                    {/* <h2 className="title">{title}</h2> */}
                    {/* <h2 className="title">{title} </h2> */}
                </div>
                <div className="section-wrapper">
                    {/* <div className="row g-2 justify-content-center row-cols-xl-6 row-cols-md-3 row-cols-sm-2 row-cols-1">
                        {categoryList.map((val, i) => (
                            <div className="col" key={i}>
                                <div className="category-item text-center">
                                    <div className="category-inner">
                                        <div className="category-thumb">
                                            <img src={`${val.imgUrl}`} alt={val.imgAlt} />
                                        </div>
                                        <div className="category-content">
                                            <Link to="/course"><h6>{val.title}</h6></Link>
                                            <span>{val.count}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>  
                        ))}
                    </div> */}
                    <div className="row g-2 justify-content-evenly align-items-center">
                        <div className="col-lg-4 col-md-8 d-flex justify-content-center">
                            <img src={img} className="category-image" />
                        </div>
                        <div className="col-lg-6 col-md-8 about-us-description">
                            {description}
                            <div className="row mt-4 justify-content-center">
                                <button onClick={scroll} style={{ width: "fit-content", color: "white" }} className="lab-btn">नोंदणी</button>
                            </div>
                        </div>

                    </div>
                    {/* <div className="text-center mt-5">
                        <Link to="/about" className="lab-btn"><span>{btnText}</span></Link>
                    </div> */}
                </div>
            </div>
        </div>
    );
}

export default Category2;