
import { Link, useLocation } from "react-router-dom";


const PageHeader = ({title, curPage}) => {
    const location =useLocation()
  
    return (
        <div className="pageheader-section">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="pageheader-content text-center">
                            <h2>{title}</h2>
                            <nav aria-label="breadcrumb">
                            {location.pathname==='/signup'?(<>
                                <ol className="breadcrumb justify-content-center">
                                    
                                        <li className="breadcrumb-item"><Link to="/">होमपृष्ठ</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">{curPage}</li>

                                    
    
                                    
                                </ol>
                                
                                        <p>फॉर्म <img src="/assets/images/icon/chrome.png" width="22" /> Chrome browser, <img src="/assets/images/icon/350963.png" width="22" /> mozila browser मधून भरावा..
</p>
                                </>):(<>
                                    <ol className="breadcrumb justify-content-center">
                                    
                              
 
                                
                                <li className="breadcrumb-item"><Link to="/">होमपृष्ठ</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">{curPage}</li>
                                
                            </ol>
                                </>)}
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default PageHeader;