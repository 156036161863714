


const GoogleMap = () => {
    return (
        <div className="map-area">
            <div className="maps">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d120059.35697327551!2d75.28851580220558!3d19.888347744627527!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bdba3d664b7aaa5%3A0xa4b4668bd4e1a3e6!2sInficom%20Solutions%20Pvt.%20Ltd!5e0!3m2!1sen!2sin!4v1706636734910!5m2!1sen!2sin"></iframe>
            </div>
        </div>
    );
}

export default GoogleMap;
