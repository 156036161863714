


const name = "जयप्रकाश टाकणखार"
const degi = "सचिव "
const desc = "+91 9860095862"


const socialList = [
    {
        link: '#',
        iconName: 'icofont-facebook',
        className: 'facebook',
    },
    {
        link: '#',
        iconName: 'icofont-twitter',
        className: 'twitter',
    },
    {
        link: '#',
        iconName: 'icofont-linkedin',
        className: 'linkedin',
    },
    {
        link: '#',
        iconName: 'icofont-instagram',
        className: 'instagram',
    },

]

const Author = () => {
    return (
        <div className="authors">
            <div className="author-thumb">
                <img src="assets/images/instructor/jayprakas-takankar.jpg" alt="rajibraj91" />
            </div>
            <div className="author-content">
                <h5>{name}</h5>
                <span>{degi}</span>
                <p>{desc}</p>
                {/* <ul className="lab-ul social-icons">
                    {socialList.map((val, i) => (
                        <li key={i}>
                            <a href={val.link} className={val.className}><i className={val.iconName}></i></a>
                        </li>
                    ))}
                </ul> */}
            </div>
        </div>
    );
}
 
export default Author;