import React from 'react'
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
const ShippingPolicy = () => {
  return (
    <div>
        <Header />
        <PageHeader title={`Shipping Policy`} curPage={`Shipping Policy`} />
        <div className='container mt-3 mb-5'>
        Since our website does not offer any physical products for sale, we do not facilitate product delivery to our users. Therefore, we do not provide a shipping policy as it is not applicable to our platform.
        <h6 className='mt-3'>Contact Details for Support or More Information</h6>
        <p>Shiv Charitra</p>
            <a href='mailto:help@shivcharitra.com'>help@shivcharitra.com</a> / <a href='mailto:support@shivcharitra.com'>support@shivcharitra.com</a>
        </div>
        

        <Footer />
    </div>
  )
}

export default ShippingPolicy