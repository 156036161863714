import React from 'react';

const ApiConfig = () => {
  const config = {
    baseURL: 'https://admin.shivcharitra.com/'
  };

  return config.baseURL;
};

export default ApiConfig;
