import React from 'react'
import './videoBg.css'
import videoBg from '../../assets/video/video.mp4'
const HeroVideo = () => {
  return (
    <div className='video-main'>
    {/* <div className="overlay"></div> */}
    <video src={videoBg} autoPlay loop muted  />
    {/* <div className="content">
        <h1>Welcome</h1>
        <p>To my site.</p>
    </div> */}
</div>
  )
}

export default HeroVideo