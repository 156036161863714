


// const subTitle = "Loved by 200,000+ students";
// const title = "Students Community Feedback";


// const studentList = [
//     {
//         imgUrl: 'assets/images/feedback/student/01.jpg',
//         imgAlt: 'student rajibraj91 rajibraj',
//         name: 'Oliver Beddows',
//         degi: 'UX designer',
//         desc: 'Rapidiously buildcollaboration anden deas sharing viaing and bleedng edgeing nterfaces fnergstcally plagiarize teams anbuilding paradgms whereas goingi forward process and monetze',
//     },
//     {
//         imgUrl: 'assets/images/feedback/student/02.jpg',
//         imgAlt: 'student rajibraj91 rajibraj',
//         name: 'Madley Pondor',
//         degi: 'UX designer',
//         desc: 'Rapidiously buildcollaboration anden deas sharing viaing and bleedng edgeing nterfaces fnergstcally plagiarize teams anbuilding paradgms whereas goingi forward process and monetze',
//     },
// ]


const Student = () => {
    return (
        <div className="student-feedbak-section padding-tb shape-img">
            <div className="container">
                <div className="section-header text-center">
                    {/* <span className="subtitle">{subTitle}</span> */}
                    {/* <h2 className="title">आमच्या बद्दल</h2> */}
                </div>
                <div className="section-wrapper">
                    <p>आमचं उद्दीष्ट छत्रपती शिवाजी महाराजांच्या महान जीवनाच्या प्रशंसकांना जोडणे आणि त्यांची उत्कृष्टता आणि योद्धापणाच्या गोष्टी प्रमोट करणे आहे. आम्ही छत्रपती शिवाजी महाराजांच्या विचारांचा आणि त्यांच्या कृतींचा प्रचार करण्याचा प्रयत्न करीत आहोत ज्याने महाराष्ट्रातील युवा आणि समाजाला त्यांच्या विचारांच्या दिशेने आणि समृद्धीने चालवण्यात मदत करण्याची इच्छा आहे. आपल्याला या आदर्शपुरस्कृत सत्यशोध जीवनातील विभागांच्या माहितीशी संपर्क साधून आपल्या जीवनात शिवाजी महाराजांची शूरता आणि विश्वास जागरूक करण्याचा प्रयत्न करू आहोत. आपलं आभास आणि सामाजिक उपस्थिती सांगण्यासाठी आमच्या संगणकीय जगातील संघटनेचा वापर करा.</p>
                </div>
            </div>
        </div>
    );
}
 
export default Student;