import { Link } from "react-router-dom";
import Logo from "../../assets/images/logo/shivlogo.png";
const desc = "छत्रपती शिवाजी महाराजांसारखा जनता राजा आपल्या महाराष्ट्राला लाभला, त्यांच्या जीवनाविषयी आणि त्यांच्या मूल्यांविषयी आपण सर्वांना माहिती असणे साहजिकच आहे. तीच माहिती आणखी वाढवण्याच्या उद्देशाने आणि त्यामध्ये आणखी वृध्दी करण्यासाठी आपली ...";
const courseTitle = "Important Links";
const quickTitle = "Quick Links";


const addressList = [
    // {
    //     iconName: 'icofont-google-map',
    //     text: 'New Elefent Road, Dhaka.',
    // },
    {
        iconName: 'icofont-phone',
        text: '+91 9049 44 50 44',
    },
    {
        iconName: 'icofont-envelope',
        text: 'info@shivcharitra.com',
    },
]

const socialList = [
    {
        iconName: 'icofont-facebook',
        siteLink: 'https://www.facebook.com/profile.php?id=61556235654270&mibextid=ZbWKwL',
        className: 'facebook',
    },
    // {
    //     iconName: 'icofont-twitter',
    //     siteLink: '#',
    //     className: 'twitter',
    // // },
    // {
    //     iconName: 'icofont-linkedin',
    //     siteLink: '#',
    //     className: 'linkedin',
    // },
    {
        iconName: 'icofont-instagram',
        siteLink: '#',
        className: 'instagram',
    },
    {
        iconName: 'icofont-youtube',
        siteLink: '#',
        className: 'youtube',
    },
    {
        iconName: 'icofont-whatsapp',
        siteLink: 'https://wa.me/9049445044?text=How may I help You',
        className: 'whatsapp',
    },
    // {
    //     iconName: 'icofont-pinterest',
    //     siteLink: '#',
    //     className: 'pinterest',
    // },
]

const courseList = [
    {
        text: 'Terms and Conditions',
        link: '/terms-and-conditions',
    },
    {
        text: 'Refund and Cancellation Policy',
        link: '/refund-and-cancellation-policy',
    },
    {
        text: 'Shipping Policy',
        link: '/shipping-policy',
    },
    {
        text: 'Privicy Policy',
        link: '/privacy-policy',
    },

]

const quickList = [
    {
        text: 'शिवचरित्र परीक्षा',
        link: '/course-single',
    },
    {
        text: 'फोटो गॅलरी',
        link: '/gallery',
    },
 
    {
        text: 'संपर्क',
        link: '/contact',
    },
    {
        text: 'आमच्या बद्दल',
        link: '/about',
    },
 
]


const tweetList = [
    {
        iconName: 'icofont-twitter',
        desc: <p>Aminur islam <a href="#">@CodexCoder Edukon #HTML_Template</a> Grab your item, 50% Big Sale Offer !!</p>,
    },
    {
        iconName: 'icofont-twitter',
        desc: <p>Somrat islam <a href="#">@CodexCoder Edukon #HTML_Template</a> Grab your item, 50% Big Sale Offer !!</p>,
    },
]

const footerbottomList = [
    {
        text: 'Faculty',
        link: '#',
    },
    {
        text: 'Staff',
        link: '#',
    },
    {
        text: 'Students',
        link: '#',
    },
    {
        text: 'Alumni',
        link: '#',
    },
]
const Footer = () => {
  return (
    <footer className="dark-view style-2  ">
    <div className="footer-top padding-tb">
        <div className="container">
            <div className="row g-4 row-cols-xl-4 row-cols-sm-2 row-cols-1 justify-content-center">
                <div className="col">
                    <div className="footer-item our-address">
                        <div className="footer-inner">
                            <div className="footer-content">
                                <div className="title">
                                    <img src={Logo} alt="education" />
                                </div>
                                <div className="content">
                                    <p>{desc}</p>
                            
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col margin-t">
                    <div className="footer-item">
                        <div className="footer-inner">
                            <div className="footer-content">
                                <div className="title">
                                    <h4>{courseTitle}</h4>
                                </div>
                                <div className="content">
                                    <ul className="lab-ul">
                                        {courseList.map((val, i) => (
                                            <li key={i}><a href={val.link}>{val.text}</a></li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col margin-t">
                    <div className="footer-item">
                        <div className="footer-inner">
                            <div className="footer-content">
                                <div className="title">
                                    <h4>{quickTitle}</h4>
                                </div>
                                <div className="content">
                                    <ul className="lab-ul">
                                        {quickList.map((val, i) => (
                                            <li key={i}><a href={val.link}>{val.text}</a></li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="col">
                    <div className="footer-item twitter-post">
                        <div className="footer-inner">
                            <div className="footer-content">
                                <div className="title">
                                    <h4>{tweetTitle}</h4>
                                </div>
                                <div className="content">
                                    <ul className="lab-ul">
                                        {tweetList.map((val, i) => (
                                            <li key={i}>
                                                <i className={val.iconName}></i>
                                                {val.desc}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                                <div className="col margin-t">
                    <div className="footer-item our-address">
                        <div className="footer-inner">
                            <div className="footer-content">
                                <div className="title">
                                <h4>Contact</h4>
                                </div>
                                <div className="content">
                                    
                                    <ul className="lab-ul office-address">
                                        {addressList.map((val, i) => (
                                            <li key={i}><i className={val.iconName}></i>{val.text}</li>
                                        ))}
                                    </ul>
                                    <ul className="lab-ul social-icons">
                                        {socialList.map((val, i) => (
                                            <li key={i}>
                                                <a href={val.siteLink} className={val.className} target="_blank"><i className={val.iconName}></i></a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="footer-bottom">
        <div className="container">
            <div className="section-wrapper">
                <p>&copy; 2024 <Link to="/">shivcharitra</Link> </p>
                {/* <div className="footer-bottom-list">
                    {footerbottomList.map((val, i) => (
                        <a href={val.link} key={i}>{val.text}</a>
                    ))}
                </div> */}
            </div>
        </div>
    </div>
</footer>
  );
};

export default Footer;
