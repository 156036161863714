import { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import Register from "../component/section/register";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { RajyaSanyojakForm } from "../component/section/RajyaSanyojakForm";
import { GroupForm } from "../component/section/GroupForm";
import { GroupA } from "../component/section/GroupA";
import { GroupC } from "../component/section/GroupC";
import { GroupD } from "../component/section/GroupD";
import { GroupF } from "../component/section/GroupF";
import { GroupM } from "../component/section/GroupM";
import { GroupN } from "../component/section/GroupN";
import { ShalaForm } from "../component/section/ShalaForm";
import { MadadFormOne } from "../component/section/MadadFormOne";

const title = "Register Now";
const socialTitle = "Register With Social Media";
const btnText = "Submit";

let socialList = [
  {
    link: "#",
    iconName: "icofont-facebook",
    className: "facebook",
  },
  {
    link: "#",
    iconName: "icofont-twitter",
    className: "twitter",
  },
  {
    link: "#",
    iconName: "icofont-linkedin",
    className: "linkedin",
  },
  {
    link: "#",
    iconName: "icofont-instagram",
    className: "instagram",
  },
  {
    link: "#",
    iconName: "icofont-pinterest",
    className: "pinterest",
  },
];

const groups = [
  { name: "आरोग्यविषयी", value: "A", subHeading: "" },
  { name: "शिक्षणाविषयी", value: "B", subHeading: "" },
  { name: "रोजगारसंबंधी", value: "C", subHeading: "" },
  { name: "विवाह संबंधी", value: "D", subHeading: "" },
];

const Madad = () => {
  const [studentModalOpen, setStudentModalOpen] = useState(false);
  const [group, setGroup] = useState({ name: "", value: "" });

  const handleGroupChange = (group) => {
    setGroup(group);
    setStudentModalOpen(false);
  };

  return (
    <Fragment>
      <Header />
      <PageHeader title={"मदत "} curPage={"मदत "} />
      <div className="login-section padding-tb section-bg">
        <div className="container-fluid">
          <div className="row d-flex gy-4 justify-content-center justify-content-lg-between">
            <div className=" col-12 col-lg-6 col-md-8 col-sm-10 px-5">
              <h2 className="text-center referral-benefits-title">
                एकमेका साह्य करू अवघा समृद्ध करूया नव भारत !
              </h2>
              <p className="about-us-description mt-5">
                मदत या समूहामध्ये आपले स्वागत ! अभिनंदन !! <br></br>
                आपण या समूहामध्ये अशी
                एक समाजव्यवस्था निर्माण करत आहोत जी समता - स्वतंत्र - बंधूता -
                मानवता या नैतिक मूल्यावर आधारित असेल ! आपण सर्व भारतीय एक परिवार
                आहोत या भावनेने समाजातील गरिब -गरजू - अनाथ - निराश्रीत अशा
                बांधवांना तसेच मध्यमवर्गीय बांधवांना अडचणीच्या वेळी शैक्षणीक '
                वैद्यकिय - विवाह -कुटुरुघोग - व्यवसाय या समस्येसाठी - अडचणी वेळी
                आर्थिक मदत करण्यासाठी एक नविन समाज निर्माण करत आहोत ! सर्व
                प्रकारची मदत हि गरज व सत्यता पडताळूनच केली जाईल या समुहातिल
                सदस्याव्दारे १ रु पासून १०० रु मदत निधी म्हणून स्विकारून
                परिवारातील गरजू सदस्यास आवश्यकते नुसार दिली जाईल<br></br>
                मदतीसाठी दिलेली माहिती चूकीची , असत्य आढळल्यास नंतर केव्हाही मदत केली जाणार नाही व कायमची सदस्यता रदद केली जाईल
              </p>
            </div>
            <div className=" col-12 col-lg-6 col-md-8 col-sm-10 sign-up-modal-parent">
              <div className="container d-flex justify-content-center align-items-center">
                <button
                  onClick={() => {
                    setStudentModalOpen(true);
                  }}
                  className="lab-btn text-white me-2 me-md-5"
                >
                  मदत मागण्यासाठी येथे स्पर्श करा
                </button>
              </div>
              <Modal
                show={studentModalOpen}
                onHide={() => setStudentModalOpen(false)}
                size="xs"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable
                className="custom-modal"
              >
                <Modal.Header
                  style={{
                    color: "#f16126",
                    display: "flex",
                    justifyContent: "center",
                    borderBottom: "none",
                  }}
                >
                  <Modal.Title
                    className="modal-title text-center"
                    id="contained-modal-title-vcenter"
                  >
                    {/* मदत मागण्यासाठी येथे स्पर्श करा */}
                    मदत उपक्रम हा संविधान जनजागृती अभियान संपल्यानंतर सदस्य नोंदणी करून राबवण्यात्त येईल.
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "10px",
                  }}
                >
                 
                  {/* {groups.map((item) => {
                    return (
                      <button
                        onClick={() => {
                          handleGroupChange(item);
                        }}
                        className="lab-btn group-form-button text-center"
                      >
                        <span>{item.name}</span>
                      </button>
                    );
                  })} */}
                </Modal.Body>
              </Modal>
              {group.value === "A" && (
                <MadadFormOne
                  group={group.name}
                />
              )}
              {group.value === "B" && (
                <MadadFormOne
                group={group.name}
              />
              )}
              {group.value === "C" && (
               <MadadFormOne
               group={group.name}
             />
              )}
              {group.value === "D" && (
               <MadadFormOne
               group={group.name}
             />
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default Madad;
