import { Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import AchievementTwo from "../component/section/achievement-2";
import Blog from "../component/section/blog";
import Instructor from "../component/section/instructor";
import Skill from "../component/section/skill";
import Student from "../component/section/student";




const subTitle = " Terms and Conditions for Shivcharitra";
const title = "Good Qualification Services And Better Skills";
const desc = "Distinctively provide acces mutfuncto users whereas transparent proceses somes ncentivize eficient functionalities rather than extensible archtectur communicate leveraged services and cross-platform.";

const year = "30+";
const expareance = "Years Of Experiences";



const aboutList = [
    {
        imgUrl: 'assets/images/about/icon/01.jpg',
        imgAlt: 'about icon rajibraj91 rajibraj',
        title: 'Skilled Instructors',
        desc: 'Distinctively provide acces mutfuncto users whereas communicate leveraged services',
    },
    {
        imgUrl: 'assets/images/about/icon/02.jpg',
        imgAlt: 'about icon rajibraj91 rajibraj',
        title: 'Get Certificate',
        desc: 'Distinctively provide acces mutfuncto users whereas communicate leveraged services',
    },
    {
        imgUrl: 'assets/images/about/icon/03.jpg',
        imgAlt: 'about icon rajibraj91 rajibraj',
        title: 'Online Classes',
        desc: 'Distinctively provide acces mutfuncto users whereas communicate leveraged services',
    },
]


const TermsCondition = () => {
  return (
    <Fragment>
    <Header />
    <PageHeader title={'Terms And Conditions'} curPage={'Terms And Conditions'} />
    <div className="about-section style-3 padding-tb section-bg">
        <div className="container">
            <div className="row justify-content-center align-items-center">
                {/* <div className="col">
                    <div className="about-left">
                        <div className="about-thumb">
                            <img src="assets/images/about/01.jpg" alt="about" />
                        </div>
                        <div className="abs-thumb">
                            <img src="assets/images/about/02.jpg" alt="about" />
                        </div>
                        <div className="about-left-content">
                            <h3>{year}</h3>
                            <p>{expareance}</p>
                        </div>
                    </div>
                </div> */}
                <div className="col-8">
                    <div className="about-right">
                        <div className="section-header">
                            <span className="subtitle">{subTitle}</span>
                            {/* <h2 className="title">{title}</h2> */}
                            <ol>
    <li>
        Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users, and others who access or use the Service.
    </li>
    <li>
        By accessing or using the Service, you agree to be bound by these Terms. If you disagree with any part of the terms, then you may not access the Service.
    </li>
    <li>
        <strong>Registration and Payment</strong>
        <ol>
            <li>
                In order to use the Service, you must register for an account and pay the required registration fees. You must provide accurate and complete information when registering for an account.
            </li>
            <li>
                You agree to pay all fees and charges associated with your account on a timely basis. Failure to pay may result in the suspension or termination of your account.
            </li>
            <li>
                All payments are non-refundable unless explicitly stated otherwise in these Terms or as required by law.
            </li>
        </ol>
    </li>
    <li>
        <strong>Content</strong>
        <ol>
            <li>
                Our Service allows you to access and participate in competitive examinations and related content. You agree to use the Service in accordance with all applicable laws and regulations.
            </li>
            <li>
                We reserve the right to modify, suspend, or discontinue the Service at any time without notice. We will not be liable to you or any third party for any modification, suspension, or discontinuation of the Service.
            </li>
        </ol>
    </li>
    <li>
        <strong>Use License</strong>
        <ol>
            <li>
                Permission is granted to access the materials (information or software) on our website for personal, non-commercial use only. This is the grant of a license, not a transfer of title.
            </li>
            <li>
                You may not:
                <ol>
                    <li>Modify or copy the materials;</li>
                    <li>Use the materials for any commercial purpose, or for any public display (commercial or non-commercial);</li>
                    <li>Attempt to decompile or reverse engineer any software contained on our website;</li>
                    <li>Remove any copyright or other proprietary notations from the materials; or</li>
                    <li>Transfer the materials to another person or "mirror" the materials on any other server.</li>
                </ol>
            </li>
        </ol>
    </li>
</ol>


                            
                        </div>
                        {/* <div className="section-wrapper">
                            <ul className="lab-ul">
                                {aboutList.map((val, i) => (
                                    <li key={i}>
                                        <div className="sr-left">
                                            <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                        </div>
                                        <div className="sr-right">
                                            <h5>{val.title}</h5>
                                            <p>{val.desc}</p>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <Student />
    <Instructor />
    <Skill />
    <AchievementTwo />
    <Blog /> */}
    <Footer />
</Fragment>
  )
}

export default TermsCondition