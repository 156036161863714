import { Fragment } from "react";
import { Link } from "react-router-dom";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import Pagination from "../component/sidebar/pagination";


const blogList = [
    {
        imgUrl: 'assets/images/blog/01.jpg',
        imgAlt: 'Blog Thumb',
        title: 'संविधान का अमृत महोत्सव : वर्ष २०२३ से २०२५ संविधान जागरूकता अभियान : घर-घर संविधान.',
        desc: 'हम भारत के लोगों ने २६ नवंबर १९४९ को संविधान सभा में भारत के संविधान को खुद के प्रति अर्पित किया । २६ नवंबर २०२४ को इस ऐतिहासिक घटना के ७५ वर्ष पूरे होने जा रहे है। हम अमृत महोत्सवी वर्ष कह सकते हैं। आपके जानकारी के लिये बताना जरूरी समजते है की, सरकारी स्तर पर संविधान अभियान की शुरुआत देश में पहली बार २००५ में नागपुर (महाराष्ट्र) से हुई थी। नागपुर जिले के सभी स्कूलों से प्रतिदिन संविधान की प्रस्तावना पढ़ने की अनूठी पहल की गयी',
        commentCount: '3',
        btnText: 'Read More',
        metaList: [
            {
                iconName: 'icofont-ui-user',
                text: 'इ झेड खोब्रागडे',
            },
            {
                iconName: 'icofont-calendar',
                text: 'Nov 22, 2022',
            },
        ],
    },
    
]



const BlogPage = () => {
    return ( 
        <Fragment>
            <Header />
            <PageHeader title={'लेख'} curPage={'लेख'} />
            <div className="blog-section padding-tb section-bg">
                <div className="container">
                    <div className="section-wrapper">
                        <div className="row row-cols-1 row-cols-md-2 row-cols-xl-3 justify-content-center g-4">
                            {blogList.map((val, i) => (
                                <div className="col" key={i}>
                                    <div className="post-item">
                                        <div className="post-inner">
                                            <div className="post-thumb">
                                                <Link to="/blog-single"><img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} /></Link>
                                            </div>
                                            <div className="post-content">
                                                <Link to="/blog-single"><h4>{val.title}</h4></Link>
                                                <div className="meta-post">
                                                    <ul className="lab-ul">
                                                        {val.metaList.map((val, i) => (
                                                            <li key={i}><i className={val.iconName}></i>{val.text}</li>
                                                        ))}
                                                    </ul>
                                                </div>
                                                <p className="clamped-blog-description">{val.desc}</p>
                                            </div>
                                            <div className="post-footer">
                                                <div className="pf-left">
                                                    <Link to="/blog-single" className="lab-btn-text">{val.btnText} <i className="icofont-external-link"></i></Link>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <Pagination />
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    );
}

export default BlogPage;