import { useState, useEffect } from "react";

import ApiConfig from "../ApiConfig";
import LoadingOverlay from 'react-loading-overlay';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';



const Course2 = ({  limit }) => {
    const baseURL = ApiConfig();
    const [loading, setLoading] = useState(false);
    const [courseList, setcourseList] =useState([]);
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
  
    const openLightbox = (index) => {
      setPhotoIndex(index);
      setLightboxOpen(true);
    };
    useEffect(() => {
        const fetchData = async () => {
          try {
            setLoading(true);
            const response = await fetch(baseURL + 'abhiyan_photo', {
              method: 'GET',
            });
      
            if (response.ok) {
              const data = await response.json();
              setcourseList(data);
              setLoading(false);
              console.log(data);
            } else {
              setLoading(false);
              console.log('Error:', response.status);
            }
          } catch (error) {
            setLoading(false);
            console.log('Error:', error.message);
          }
        };
      
        fetchData();
      }, []);
    return (
        <LoadingOverlay active={loading} spinner text="Loading Images....">
        <div className="course-section padding-tb section-bg">
            <div className="container">
                <div className="section-header text-center">
                  
                </div>
                <div className="section-wrapper">
         
                    <div className="row g-1 justify-content-center">
                        {courseList.map((item, i) => {
                            if (limit) {
                                if (i < 8) {
                                    return (
                                        <div className="home-gallery-images col-auto"
                                        onClick={() => openLightbox(i)}
                                        key={i}
                                        >
                                            <img className="" src={`${baseURL}${item.photo}`} alt="gallery-image" />
                                        </div>
                                    )
                                }
                            } else {
                                return (
                                    <div className="home-gallery-images col-auto"
                                    onClick={() => openLightbox(i)}
                                    key={i}
                                    >
                                        <img className="" src={`${baseURL}${item.photo}`} alt="gallery-image" />
                                    </div>
                                )
                            }

                        })}
                    </div>
                </div>
            </div>
        </div>
              {lightboxOpen && (
        <Lightbox
          mainSrc={`${baseURL}${courseList[photoIndex].photo}`}
          nextSrc={`${baseURL}${courseList[(photoIndex + 1) % courseList.length].photo}`}
          prevSrc={`${baseURL}${courseList[(photoIndex + courseList.length - 1) % courseList.length].photo}`}
          onCloseRequest={() => setLightboxOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + courseList.length - 1) % courseList.length)
          }
          onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % courseList.length)}
        />
      )}
        </LoadingOverlay>
    );
}

export default Course2;