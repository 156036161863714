import { Fragment, useState } from "react";

import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";

import Modal from "react-bootstrap/Modal";
import { RajyaSanyojakForm } from "../component/section/RajyaSanyojakForm";
// import { GroupForm } from "../component/section/GroupForm";
import { GroupA } from "../component/section/GroupA";






const groups = [
  { name: "Group A", value: "A", subHeading: "५ वी ते 12 वी विद्यार्थी"  },
  { name: "Group B", value: "B", subHeading: "खुला गट (सर्वांसाठी)" },
  // { name: "Group C", value: "C", subHeading: "११ वी ते १२ वी विद्यार्थी"  },
  // {
  //   name: "Group D",
  //   value: "D",
  //   subHeading: "शेतकरी / कामगार / महिला - गृहिणी , बचतगट , अंगणवाडी सेविका ' सेवानिवृत्त कर्मचारी , उद्योग - व्यवसाय - व्यापार , सैनिक ( आर्मी )",
  //   sec: ["शेतकरी / कामगार", "महिला - गृहिणी", "बचतगट", "अंगणवाडी सेविका", "सेवानिवृत्त कर्मचारी", "उद्योग - व्यवसाय - व्यापार", "सैनिक ( आर्मी )"]
  // },
  // {
  //   name: "Group E",
  //   value: "E",
  //   subHeading: "कृषी नर्सिंग, आय टी आय, मेडिकल, इंजि ., तंत्र शिक्षण विद्यार्थी, इतर समकक्ष विद्यार्थी",
  //   sec: []
  // },
  // {
  //   name: "Group F",
  //   value: "F",
  //   subHeading: "पदवी, पदव्युत्तर विद्यार्थी एम पि एस सी , यू पी एस सी / स्पर्धा परिक्षा विद्यार्थी",
  //   sec: []
  // },
  // {
  //   name: "Group G",
  //   value: "G",
  //   subHeading: "प्राथमिक ' माध्यमिक , उच्च माध्यमिक शिक्षक डि टि एड, बि एड, बि पि एड,एम एड, एम पी एड विद्यार्थी",
  //   sec: []
  // },
  // {
  //   name: "Group H",
  //   value: "H",
  //   subHeading: "कनिष्ठ, वरिष्ठ महाविदयालय प्राध्यापक, अधिव्याख्याता",
  //   sec: []
  // },
  // {
  //   name: "Group I",
  //   value: "I",
  //   subHeading: "वकिल महासंघ, डॉक्टर असोशिएन, विधी विद्यार्थी",
  //   sec: []
  // },
  // {
  //   name: "Group J",
  //   value: "J",
  //   subHeading: "एस टी महामंडळ , एम इ सी बी , कृषि उत्पन्न बाजार समीती कर्मचारी, कला, सांस्कृतिक, क्रिडा विभाग",
  //   sec: []
  // },
  // {
  //   name: "Group K",
  //   value: "K",
  //   subHeading: "आरोग्य, शिक्षण, कृषि कर्मचारी एल आय सी, बँकिंग कर्मचारी",
  //   sec: []
  // },
  // {
  //   name: "Group L",
  //   value: "L",
  //   subHeading: "चतुर्थ श्रेणी कर्मचारी विभाग, पोलीस कर्मचारी विभाग , कनिष्ठ व वरिष्ठ लिपिक विभाग, रेल्वे / डाक कर्मचारी विभाग",
  //   sec: []
  // },
  // {
  //   name: "Group M",
  //   value: "M",
  //   subHeading: " विधानसभा लोकसभा मंत्रिमंडळ व मंत्रालयातील कर्मचारी विभाग , महानगर पालिका नगरपालिका सदस्य विभाग, जिल्हा परिषद पंचायत समिती/ ग्रामपंचायत सदस्य विभाग",
  //   sec: []
  // },
  // {
  //   name: "Group N",
  //   value: "N",
  //   subHeading: "विधानसभा, लोकसभा, मंत्री मंडळ, महानगर पालिका, नगरपालिला, जिल्हा परिषद, पंचायत समिती, ग्रामपंचायत व इतर सभागृहाचे सदस्य",
  //   sec: []
  // },
  // { name: "Group O", value: "O", subHeading: "खुला गट सर्वांसाठी", sec: [] },
];




const instructorOptions = [
  { name: "राज्य संयोजक", value: "division", subHeading: "", category:"rajye-sanyojak" },
  { name: "जिल्हा संयोजक", value: "state", subHeading: "", category:"jilha-sanyojak" },
  { name: "तालुका संयोजक", value: "district", subHeading: "", category:"taluka-sanyojak"  },
  { name: "गट संयोजक", value: "taluka", subHeading: "", category:"gat-sanyojak"  },
  {


    name: "शाळा / कॉलेज / क्लासेस/ अँकेडमी/ विद्यापिठ",
    value: "block",
    subHeading: "sanstha-sanyojak",
    category:"sanstha-sanyojak" 
  },
  { name: "संस्था/ मंडळ/ संघटना", value: "sansta", subHeading: "" , category:"shala-sanyojak" },
];

const SignupPage = () => {
  const [studentModalOpen, setStudentModalOpen] = useState(false);
  const [instructorModalOpen, setInstructorModalOpen] = useState(false);
 
  const [sanyojakForm, setsanyojakForm]=useState(false);
  const [group, setGroup] = useState({ name: "", value: "" });

  const handleGroupChange = (group) => {
    setGroup(group);
    setStudentModalOpen(false);
    setInstructorModalOpen(false);
    setsanyojakForm(false);
  };
  const handleClick = () => {
    setsanyojakForm(true);
    setStudentModalOpen(false);
    setGroup(false);
  };
  return (
    <Fragment>
      <Header />
      <PageHeader title={"नोंदणी"} curPage={"नोंदणी"} />
      <div className="login-section padding-tb section-bg">
        <div className="container-fluid">
          <div className="row d-flex gy-4 justify-content-center justify-content-lg-between">
            <div className=" col-12 col-lg-6 col-md-8 col-sm-10 px-5">
              <h2 className="text-center referral-benefits-title">
                रेफरलचे फायदे
              </h2>
              <p className="about-us-description mt-5">
              छत्रपती शिवाजी महाराज सन्मान परीक्षा २०२४ अभियानास आपल्या मित्र - परिवारास जोडून स्टार प्रचारक व्हा ! १०० + सदस्य जोडल्यास संविधान जनजागृती अभियान स्टार प्रचारक प्रमाणपत्र तथा केलेल्या परिक्षार्थी नोंदणीच्या १० % मानधन देखील मिळेल.
              </p>
              <p>नोंदणी केलेल्या मो. नं वरती व्हाट्सअप,मेल द्वारे वेळापत्रक पाठवले जाईल/संस्थेच्या वेबसाइटवर देखील प्रसारित करण्यात येईल.
परिक्षा MCQ type असेल मोबाईल,लैपटोप, संगणक वर देता येईल.
प</p>
              <ol>
                <li>परीक्षा मराठी व इंग्रजी दोन्ही पैकी एका भाषेत देता येईल. </li>
                <li>अभ्यास करण्यासाठी आपण शिवाजी महाराज्यांच्या जीवनावरील पुस्तके आणि इतर लेख वाचू शकतात, आमच्या कडूनही अशी अभ्यासासाठी काही माहिती वेगवेगळ्या स्वरुपात आम्ही उपलब्ध करून देणार आहोत</li>
                <li>ऑनलाईन परिक्षा दिनांक 20/04/2024 ते 26/04/2024 या काळात आयोजित करण्यात येणार आहे. या काळात वेगवेगळ्या टप्प्यात परीक्षा घेतली जातील त्याबद्दल चे वेळापत्रक https://shivcharitra.com या संकेतस्थळावर प्रदर्शित करण्यात येईल.
</li>
                <li>परिक्षार्थींनी भरलेली माहिती चुकीची आढळल्यास सदर परिक्षार्थीस बक्षीसास अपात्र केले जाईल.
</li>
              
             
     </ol>
            </div>
            <div className=" col-12 col-lg-6 col-md-8 col-sm-10 sign-up-modal-parent">
              <div className="container d-flex justify-content-center align-items-center">
                <button
                  onClick={() => {
                    setStudentModalOpen(true);
                  }}
                  className="lab-btn text-white  me-md-5"
                >
                  परिक्षार्थी नोंदणी
                </button>

                <button
                  onClick={() => {
                    handleClick(true);
                  }}
                  className="lab-btn text-white ms-0 ms-md-5 "
                >
                  संयोजक नोंदणी
                </button>
              </div>
              
              <Modal
                show={studentModalOpen}
                onHide={() => setStudentModalOpen(false)}
                size="xs"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable
                className="custom-modal"
              >
                <Modal.Header
                  style={{
                    color: "#f16126",
                    display: "flex",
                    justifyContent: "center",
                    borderBottom: "none",
                  }}
                >
                  <Modal.Title
                    className="modal-title"
                    id="contained-modal-title-vcenter"
                  >
                    परिक्षार्थी नोंदणी
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "10px",
                  }}
                >
                  {groups.map((item) => {
                    return (
                      <button
                        onClick={() => {
                          handleGroupChange(item);
                        }}
                        className="bg-light text-danger text-left"
                      >
                        <span> {item.subHeading}</span>
                      </button>
                    );
                  })}
                </Modal.Body>
              </Modal>
              <Modal
                show={instructorModalOpen}
                onHide={() => setInstructorModalOpen(false)}
                size="xs"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable
                className="custom-modal"
              >
                <Modal.Header
                  style={{
                    color: "#f16126",
                    display: "flex",
                    justifyContent: "center",
                    borderBottom: "none",
                  }}
                >
                  <Modal.Title id="contained-modal-title-vcenter">
                    संयोजक नोंदणी
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "10px",
                  }}
                >
                  {instructorOptions.map((item) => {
                    return (
                      <button
                        onClick={() => {
                          handleGroupChange(item);
                        }}
                        className="bg-light text-danger"
                      >
                        {item.name}
                      </button>
                    );
                  })}
                </Modal.Body>
              </Modal>
              <div className="mt-5">
                {sanyojakForm &&
                  <RajyaSanyojakForm
                    amount={699}
                    hideReferral={true}
                    title="संयोजक"
                   category="rajye-sanyojak"
                                />
                }

                </div>

              {group.value === "A" && (
                <GroupA
                  amount={99}
                  group={group.name}
                  subheading={group.subHeading}
                  groupvalue={group.value}
                />
              )}
              {group.value === "B" && (
                <GroupA
                  amount={199}
                  group={group.name}
                  subheading={group.subHeading}
                  groupvalue={group.value}
                />
              )}
              {group.value === "C" && (
                <GroupA
                  amount={200}
                  group={group.name}
                  subheading={group.subHeading}
                  groupvalue={group.value}
                />
              )}
              {group.value === "D" && (
                <GroupA
                  amount={200}
                  group={group.name}
                  subheading={group.subHeading}
                  groupvalue={group.value}
                />
              )}
              {group.value === "E" && (
                <GroupA
                  amount={200}
                  group={group.name}
                  subheading={group.subHeading}
                  groupvalue={group.value}
                />
              )}
              {group.value === "F" && (
                <GroupA
                  amount={400}
                  group={group.name}
                  subheading={group.subHeading}
                  groupvalue={group.value}
                />
              )}
              {group.value === "G" && (
                <GroupA
                  amount={400}
                  group={group.name}
                  subheading={group.subHeading}
                  groupvalue={group.value}
                />
              )}
              {group.value === "H" && (
                <GroupA
                  amount={400}
                  group={group.name}
                  subheading={group.subHeading}
                  groupvalue={group.value}
                />
              )}
              {group.value === "I" && (
                <GroupA
                  amount={400}
                  group={group.name}
                  subheading={group.subHeading}
                  groupvalue={group.value}
                />
              )}
              {group.value === "J" && (
                <GroupA
                  amount={400}
                  group={group.name}
                  subheading={group.subHeading}
                  groupvalue={group.value}
                />
              )}
              {group.value === "K" && (
                <GroupA
                  amount={400}
                  group={group.name}
                  subheading={group.subHeading}

                  groupvalue={group.value}
                />
              )}
              {group.value === "L" && (
                <GroupA
                  amount={400}
                  group={group.name}
                  subheading={group.subHeading}
                  groupvalue={group.value}
                />
              )}
              {group.value === "M" && (
                <GroupA
                  amount={400}
                  group={group.name}
                  subheading={group.subHeading}
                  groupvalue={group.value}
                />
              )}
              {group.value === "N" && (
                <GroupA
                  amount={400}
                  group={group.name}
                  subheading={group.subHeading}
                  groupvalue={group.value}
                />
              )}
              {group.value === "O" && (
                <GroupA
                  amount={400}
                  group={group.name}
                  subheading={group.subHeading}
                  groupvalue={group.value}
                />
              )}

            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default SignupPage;
