import { Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeaderTwo from "../component/layout/pageheader-2";
import Author from "../component/sidebar/author";



const CourseSingle = () => {
    return (
        <Fragment>
            <Header />
            <PageHeaderTwo />
            <div className="course-single-section padding-tb section-bg">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="main-part">
                                <div className="course-item">
                                    <div className="course-inner">
                                        <div className="course-content">
                                            {/* <h3>संविधान आहे महान, सर्वांना हक्‍क समान दर्जाची, संधीची समानता, हीच संविधानाची महानता</h3> */}
                                            
                                            <h4 style={{paddingTop: "5px"}}>परीक्षेचे नियम</h4>
                                            <ul className="lab-ul " style={{fontSize:"20px"}}>
                                                <li><i className="icofont-tick-mark"></i>परीक्षा मराठी व इंग्रजी दोन्ही पैकी एका भाषेत देता येईल.</li>
                                                <li><i className="icofont-tick-mark"></i>अभ्यास करण्यासाठी आपण शिवाजी महाराज्यांच्या जीवनावरील पुस्तके आणि इतर लेख वाचू शकतात, आमच्या कडूनही अशी अभ्यासासाठी  काही माहिती वेगवेगळ्या स्वरुपात आम्ही उपलब्ध करून देणार आहोत</li>
                                                <li><i className="icofont-tick-mark"></i>ऑनलाईन परिक्षा दिनांक 20/04/2024 ते 26/04/2024 या काळात आयोजित करण्यात येणार आहे. या काळात वेगवेगळ्या टप्प्यात परीक्षा घेतली जातील त्याबद्दल चे वेळापत्रक https://shivcharitra.com या संकेतस्थळावर प्रदर्शित करण्यात येईल. </li>

                                              
                                                <li><i className="icofont-tick-mark"></i>परिक्षार्थींनी भरलेली माहिती चुकीची आढळल्यास सदर परिक्षार्थीस बक्षीसास अपात्र केले जाईल.</li>

                                                
                                               
    
                  


                                            </ul>
                                        </div>
                                    </div>
                                </div>

 
                                {/* <Author /> */}
                                {/* <Comment /> */}
                                {/* <Respond /> */}
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="sidebar-part">
                                {/* <CourseSideDetail />
                                <CourseSideCetagory /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    );
}

export default CourseSingle;