import {  Fragment, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import Achievement from "../component/section/achievement";
import Category2 from "../component/section/category2"
import Category3 from "../component/section/category3"

const subTitle = "जयप्रकाश टाकणखार";
const title = "हमारा संकल्प सुशिक्षीत, सुरक्षित, समृद्ध भारत";
const description = "राज्य संयोजक हे संविधान जनजागृती अभियान याचा प्रसार व प्रचार करण्यासाठी तसेच परीक्षार्थीची नोंदणी करून घेण्यासाठी जिल्हा संयोजक, तालुका संयोजक , गट संयोजक यांची नेमणूक करण्याचा अधिकार राज्य संयोजकास आहे. राज्य संजोयक हे अभियानाच्या प्रक्रियेत सूचना, बदल, व नियोजन प्रक्रियेत मार्गदर्शक म्हणून देखील सहभागी होऊ शकतील"
const sanyojakNiyam = [
    "- राज्य संयोजक यांनी स्वतःच्या रेफरल आयडी वर केलेल्या जिल्हा, तालुका, गट संयोजक सर्वांनी मिळून केलेल्या नोंदणीवर २ % प्रवासभत्ता / मानधन म्हणून मिळेल",
    "- निर्धारित केलेल्या परीक्षार्थी संख्या पूर्ण न केल्यास सदरील संयोजक बक्षीस पात्र नसेल.",
    "- संयोजकांनी आपला परिचार्ती नोंदणी डाटा ऑनलाईन वेबसाईटवर देखील उपलब्ध असेल परंतु लेखी स्वरूपात डायरीमध्ये संकलित करून ठेवणे आवश्यक असेल.",
    "- कोणत्याही संयोजकाकडून गैरवर्तन अथवा कामांमध्ये दोष आढळून आल्यास सदरील संयोजकास कमी करण्याचा कायमस्वरूपी काढून टाकण्याचा निर्णय आयोजका अधीन असेल.",
    "- कोणत्याही संयोजकाची नियुक्ती ही कायमस्वरूपी नसून अभियानाच्या काळापूर्ती मर्यादित असेल."
]

let categoryItemList = [
    {
        imgUrl: 'assets/images/instructor/pankaj-bansode.jpg',
        imgAlt: 'category',
        title: 'मा.पंकज बनसोडे',
        desc: 'मानवता रक्षक ग्रूप संभाजीनगर',
    },
    {
        imgUrl: 'assets/images/instructor/babasaheb-manare.jpg',
        imgAlt: 'category',
        title: 'मा. बाबासाहेब मनेरे',
        desc: 'ए.बी. टि.व्ही. न्युज चॅनल, पत्रकार',
    },

    {
        imgUrl: 'assets/images/instructor/pavan-late.jpg',
        imgAlt: 'category',
        title: 'मा.पवन लाटे',
        desc: 'सकल मराठा परिवार, राज्य समन्वयक',
    },
    {
        imgUrl: 'assets/images/instructor/dhampal-vidya.jpg',
        imgAlt: 'category',
        title: 'मा.धम्मपाल विद्यागर',
        desc: 'सामाजिक कार्यकर्ते, बीड',
    },
    // {
    //     imgUrl: 'assets/images/instructor/sandipbhai.jpg',
    //     imgAlt: 'category',
    //     title: 'मा. संदिपभाई निकुंभ',
    //     desc: 'दयावान सरकार, मुंबई',
    // },
    {
        imgUrl: 'assets/images/instructor/mahesh.jpeg',
        imgAlt: 'category',
        title: 'मा. महेश तांदळे',
        desc: 'राज्य उपनेता महाराष्ट्र राज्य जुनी पेन्शन हक्क संघटनलातूर',
    },
    {
        imgUrl: 'assets/images/instructor/single/categoryimage.png',
        imgAlt: 'category',
        title: 'मा.मारोती खंदारे',
        desc: 'राज्य अध्यक्ष,महाराष्ट्र राज्य शेतमजूरयुनियन (लाल बाबटा)',
    },
    {
        imgUrl: 'assets/images/instructor/subod.jpg',
        imgAlt: 'category',
        title: 'मा.सुबोध शिंदे',
        desc: '(सामाजिक कार्यकर्ते)',
    },
    {
        imgUrl: 'assets/images/instructor/sailesh.jpeg',
        imgAlt: 'category',
        title: 'मा.शैलेश कांबळे',
        desc: 'बिड जिल्हा अध्यक्ष पूर्व वं,.ब,आघाडी',
    },
    {
        imgUrl: 'assets/images/instructor/namdev.jpeg',
        imgAlt: 'category',
        title: 'मा.नामदेवराव आयवळे',
        desc: 'अध्यक्ष, होलार समाज संस्था, पुणे',
    },
    {
        imgUrl: 'assets/images/instructor/salendra.jpeg',
        imgAlt: 'category',
        title: 'मा.शैलेंद्र मिसाळ',
        desc: 'अध्यक्ष क्रांती दल',
    },
    {
        imgUrl: 'assets/images/instructor/madhukar.jpg',
        imgAlt: 'category',
        title: 'मा.मधुकर घायदार',
        desc: 'संपादक शिक्षक, ध्येय नाशिक',
    },
    {
        imgUrl: 'assets/images/instructor/dadarav.jpg',
        imgAlt: 'category',
        title: 'मा. दादाराव नांगरे',
        desc: 'NSU अध्यक्ष मुंबई' ,
    },

    {
        imgUrl: 'assets/images/instructor/single/categoryimage.png',
        imgAlt: 'category',
        title: 'मा.लक्ष्मण पोटभरे',
        desc: 'शिक्षण विभाग,माजलगाव',
    },
    {
        imgUrl: 'assets/images/instructor/single/categoryimage.png',
        imgAlt: 'category',
        title: 'मा.फुलचंद डोंगरे',
        desc: 'नर्सिंग कॉलेज,विभाग मराठवाडा',
    },
    {
        imgUrl: 'assets/images/instructor/single/categoryimage.png',
        imgAlt: 'category',
        title: 'मा. खंडु जाधव',
        desc: 'राष्ट्रसंत कैकाडी महाराज समाज परिषद',
    },
    {
        imgUrl: 'assets/images/instructor/rajkumar-kamble.jpg',
        imgAlt: 'category',
        title: 'मा. राजकुमार कांबळे',
        desc: 'अध्यक्ष महामाया आधार संस्था छ. संभाजीनगर',
    },
    {
        imgUrl: 'assets/images/instructor/jayatayi.jpeg',
        imgAlt: 'category',
        title: 'मा . जयाताई बनसोडे',
        desc: 'सामाजिक कार्यकर्त्या मुंबई ',
    },

]



const InstructorPage = () => {

    const [viewMore, setViewMore] = useState(false)

    const handleClickScroll = () => {
        const element = document.getElementById('form-wrapper');
        if (element) {
          // 👇 Will scroll smoothly to the top of the next section
          element.scrollIntoView({ behavior: 'smooth' });
        }
      };

    return (
        <Fragment>
            <Header />
            <PageHeader title={'राज्य संयोजक'} curPage={'राज्य संयोजक'} />
            <Category2 description={description} img={"assets/images/instructor/team.jpg"} scroll={handleClickScroll}/>
            <div className="category-section padding-tb section-bg style-3">
                <div className="container pb-5">
                    <div className="section-header text-center">
                        <span className="subtitle" style={{letterSpacing: "0px"}}>{subTitle}</span>
                        <h2 className="title">{title}</h2>
                        {/* <h2 className="title">{title2}</h2> */}
                    </div>
                    <div className="section-wrapper">
                        <div className="row g-4 justify-content-center row-cols-lg-4 row-cols-md-3 row-cols-sm-2 row-cols-1">
                            {categoryItemList.map((val, i) => (
                                viewMore ? (
                                    <div className="col" key={i}>
                                        <div className="category-item text-center h-100">
                                            <div className="category-inner h-100">
                                                <div className="category-thumb">
                                                    <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} className="instructor-image-2" />
                                                </div>
                                                <div className="category-content">
                                                    <Link to="/signup"><h4>{val.title}</h4></Link>
                                                    <p>{val.desc}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    i < 4 &&
                                    <div className="col" key={i}>
                                        <div className="category-item text-center h-100">
                                            <div className="category-inner h-100">
                                                <div className="category-thumb">
                                                    <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} className="instructor-image-2" />
                                                </div>
                                                <div className="category-content">
                                                    <Link to="/signup"><h4>{val.title}</h4></Link>
                                                    <p>{val.desc}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )

                            ))}
                        </div>
                        <div className="row">
                        </div>
                        <div className="text-center mt-5">
                            <button onClick={() => { setViewMore(!viewMore) }} className="lab-btn"><span>{viewMore ? "See less" : "View more"}</span></button>
                        </div>
                    </div >
                </div >
                <Category3 category="rajye-sanyojak" sanyojakNiyam={sanyojakNiyam} amount={1000} hideReferral={true}/>
            </div >
            {/* <Student /> */}
            < Achievement />
            <Footer />
        </Fragment >
    );
}

export default InstructorPage;