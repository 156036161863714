import { Component, Fragment, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../component/layout/footer";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import ApiConfig from '../component/ApiConfig';
import LoadingOverlay from 'react-loading-overlay';
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";




const LoginPage = () => {
    const baseURL = ApiConfig();
     const navigate = useNavigate()
    const [loading,setLoading]=useState(false)
    const [mobileno, setMobiloNo] =useState('')


    const handleSubmit = async () => {
     
        setLoading(true)
        try {
          setLoading(false)
          const apiUrl = baseURL+'sabhasadrepayment';
          const requestBody = {
            mobileno: mobileno
          };
    
          const response = await axios.post(apiUrl, requestBody);
       
          console.log(response.data);
          if(response.data.status===true){
            setLoading(false)
            const paymentToken= response.data.paymentkey;
      
             
            navigate(`/sabhasad-payment-details/${paymentToken}`)
          }else{
            setLoading(false)
            Swal.fire({
                icon:'warning',
                text:response.data.message,
            })
          }
  
        } catch (error) {
          console.error(error);
          setLoading(false)
          Swal.fire({
            icon:'warning',
            text:error,
        })
        }
      
      };
    
    
      const [show, setShow] = useState(false);
    
      const handleClose = () => setShow(false);
      const handleShow = () => setShow(true);
    return (
        <Fragment>
            <Header />
            <LoadingOverlay active={loading} spinner text="Processing..........">
            <PageHeader title={'Login Page'} curPage={'Login'} />
            <div className="login-section padding-tb section-bg">
                <div className="container">
                    <div className="account-wrapper">
                        
                    <a type="button" href="http://student.shivcharitra.com/" className="d-block lab-btn text-white">परिक्षार्थी लॉगिन</a>
                    <a type="button" href="http://sanyojak.shivcharitra.com/" className="d-block lab-btn text-white mt-3 mb-3">संयोजक लॉगिन</a>

                        <div className="account-bottom">
                            <span className="d-block cate pt-10">Don’t Have any Account?  <Link to="/signup">Sign Up</Link></span>
                            {/* <span className="or"><span>or</span></span>
                            <h5 className="subtitle">{socialTitle}</h5>
                            <ul className="lab-ul social-icons justify-content-center">
                                {socialList.map((val, i) => (
                                    <li key={i}>
                                        <a href={val.link} className={val.className}><i className={val.iconName}></i></a>
                                    </li>
                                ))}
                            </ul> */}
                        </div>
                    </div>
                </div>
            </div>
            <Modal 
            show={show} 
            onHide={handleClose}   
            centered
  scrollable
  className="custom-modal">
        <Modal.Header closeButton>
          <Modal.Title>तुमचा नोंदणीकृत मोबाईल क्रमांक टाका</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="form-group">
                <label>मोबाईल नंबर</label>
                <input type="text" name="mobile" className="form-control" value={mobileno} onChange={(e)=>setMobiloNo(e.target.value)} />
            </div>
        </Modal.Body>
        <Modal.Footer>
        <Button variant="success" onClick={handleSubmit}>
        तपासा आणि आता पैसे द्या
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
         
        </Modal.Footer>
      </Modal>
      </LoadingOverlay>
            <Footer />
        </Fragment>
    );
}
 
export default LoginPage;