
import { Link } from "react-router-dom";
import Img from "../../assets/images/category/chatrapai_shivaji_maharaj.webp"


const btnText = "आणखी वाचा";


const categoryList = [
    {
        imgUrl: 'assets/images/category/icon/01.jpg',
        imgAlt: 'category rajibraj91 rajibraj',
        title: 'Computer Science',
        count: '24 Course',
    },
    {
        imgUrl: 'assets/images/category/icon/02.jpg',
        imgAlt: 'category rajibraj91 rajibraj',
        title: 'Civil Engineering',
        count: '04 Course',
    },
    {
        imgUrl: 'assets/images/category/icon/03.jpg',
        imgAlt: 'category rajibraj91 rajibraj',
        title: 'Business Analysis',
        count: '27 Course',
    },
    {
        imgUrl: 'assets/images/category/icon/04.jpg',
        imgAlt: 'category rajibraj91 rajibraj',
        title: 'Data Science Analytics',
        count: '28 Course',
    },
    {
        imgUrl: 'assets/images/category/icon/05.jpg',
        imgAlt: 'category rajibraj91 rajibraj',
        title: 'Learning Management',
        count: '78 Course',
    },
    {
        imgUrl: 'assets/images/category/icon/06.jpg',
        imgAlt: 'category rajibraj91 rajibraj',
        title: 'Computer Engineering',
        count: '38 Course',
    },
]


const Category = () => {
    return (
        <div className="category-section mt-3">
            <div className="container">
                <div className="section-header text-center">
                    {/* <span className="subtitle">{subTitle}</span> */}
                    {/* <h2 className="title">{title}</h2> */}
                    <h2 className="title">छत्रपती शिवाजी महाराज चरित्र सन्मान परीक्षा 2024</h2>
                </div>
                <div className="section-wrapper">
                    {/* <div className="row g-2 justify-content-center row-cols-xl-6 row-cols-md-3 row-cols-sm-2 row-cols-1">
                        {categoryList.map((val, i) => (
                            <div className="col" key={i}>
                                <div className="category-item text-center">
                                    <div className="category-inner">
                                        <div className="category-thumb">
                                            <img src={`${val.imgUrl}`} alt={val.imgAlt} />
                                        </div>
                                        <div className="category-content">
                                            <Link to="/course"><h6>{val.title}</h6></Link>
                                            <span>{val.count}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>  
                        ))}
                    </div> */}
                    <div className="row g-2 justify-content-evenly align-items-center">
                        <div className="col-lg-4 col-md-8 d-flex justify-content-center">
                            <img src={Img} className="img-fluid" />
                        </div>
                        <div className="col-lg-6 col-md-8 about-us-description">
                        
                            "शिवचरित्र सन्मान परीक्षा" छत्रपती शिवाजी महाराज यांच्या जीवनकार्याचा प्रसार व प्रचार करणे, छत्रपती शिवाजी महाराजांच्या जीवन
                            कार्यावर संशोधन व अभ्यास करणे आदी उपक्रम राबविण्याचे नियोजन आहे. सदर परीक्षा हि ऑनलाइन असेल.
                            छत्रपती शिवाजी महाराज हे भारतातील मराठा साम्राज्याचे संस्थापक होते. छत्रपती शिवाजी महाराजांनी शिस्तबद्ध लष्कर व सुसंघटित
                            प्रशासकीय यंत्रणेच्या बळावर एक सामर्थ्यशाली आणि प्रागतिक राज्य उभे के ले. छत्रपती शिवाजी महाराज यांच्या विचारांचा जागर सर्व
                            मनामनात व्हावा तसेच महाराजांचे विचार, त्यांच्याबद्दलची माहिती पोहोचावी, या उद्देशाने शिवचरित्र सन्मान परीक्षेचे आयोजन के लेले

                            आहे.
                            <div className="d-flex ">
                                 <a  href="https://wa.me/9049445044?text=How may I help You" target="_blank" className="lab-btn text-white">संपर्क करा  <i className="icofont-whatsapp"></i></a>
                                 <Link className="lab-btn text-white ms-auto"  to="/about">अधिक वाचा</Link>
                            </div>
                        </div>
                    </div>
                    {/* <div className="text-center mt-5">
                        <Link to="/about" className="lab-btn"><span>{btnText}</span></Link>
                        <a href="/assets/pdf/savidhan.pdf" target="_blank" type="button" className="lab-btn ms-2"><span>संविधान सन्मान परीक्षा माहिती पुस्तिका</span></a>
                    </div> */}
                </div>
            </div>
        </div>
    );
}

export default Category;